import React, { Component, useEffect } from 'react'
import { translations } from '@aws-amplify/ui-react'
import { Auth, Amplify, I18n, Hub } from 'aws-amplify'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import Router from './router'

import Processing from './pages/processing'
import Login from './pages/login'
import { amplifyConfig } from './config/amplify'
import {
  AWS_COGNITO_AUTH_DOMAIN,
  AWS_COGNITO_SHARED_DOMAIN
} from './config/env'

import store from './store'

const settings = {
  aws_cognito_region: amplifyConfig.awsCognitoRegion,
  aws_user_pools_id: amplifyConfig.awsUserPoolsId,
  aws_user_pools_web_client_id: amplifyConfig.awsUserPoolsWebClientId,
  oauth: {
    domain: AWS_COGNITO_AUTH_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    clientId: amplifyConfig.awsUserPoolsId,
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

if (window.location.hostname !== 'localhost') {
  // configura para que não tenha que se autenticar caso já esteja logado em um subdomínio
  settings.cookieStorage = {
    domain: AWS_COGNITO_SHARED_DOMAIN,
    path: '/',
    sameSite: 'strict',
    secure: true
  }
}

Amplify.configure(settings)
I18n.putVocabularies(translations)
I18n.setLanguage('pt-br')

const AuthenticationWrapper = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentUser, setCurrentUser] = React.useState()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setCurrentUser(currentUser)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))

    const unsubscribe = Hub.listen('auth', data => {
      if (data.payload.event === 'signIn') {
        setCurrentUser(data.payload.data.username)
      } else if (data.payload.event === 'signOut') {
        setCurrentUser(undefined)
      }
    })
    return unsubscribe
  }, [])

  if (loading) {
    return <Processing />
  }

  if (!currentUser) {
    return <Login />
  }

  return <App />
}

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default AuthenticationWrapper

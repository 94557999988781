import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

export default function Category ({ category, categoryIndex }) {
  return (
    <Draggable draggableId={category.id} index={categoryIndex}>
      {provided => (
        <div
          className='m-2'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className='py-2 px-3'
            style={{
              borderRadius: 8,
              fontSize: 12,
              minHeight: 20,
              boxShadow: '0 0 0 1px #091e4240',
              borderColor: '#ebecf0',
              backgroundColor: 'white'
            }}
          >
            {category.content}
          </div>
        </div>
      )}
    </Draggable>
  )
}

import axios from 'axios'
import { toCamelCase } from '@indrema/formatters'
import { hashObject } from '@indrema/crypto'

import { getUserData } from '../utils'
import { SCOPE, SQS_BASE_URL } from '../config/env'

export const createQueue = async () => {
  const { username, email } = await getUserData()
  const scope = SCOPE

  const now = new Date().getTime()

  const queueNameHash = hashObject({
    username,
    front: 'spread',
    email,
    scope,
    now
  })

  const url = `${SQS_BASE_URL}/create_queue`

  const { data } = await axios.post(url, {
    queue_name: `${scope}-${queueNameHash}.fifo`
  })

  const formattedData = toCamelCase(data)
  return formattedData.queueUrl
}

export const Types = {
  RESET: 'RATING_THEME/RESET',
  BOOTSTRAP: 'RATING_THEME/BOOTSTRAP',
  SET_GRAU_INFORMALIDADE_VENDAS: 'RATING_THEME/SET_GRAU_INFORMALIDADE_VENDAS',
  SET_CONFIABILIDADE_DEMONSTRATIVO:
    'RATING_THEME/SET_CONFIABILIDADE_DEMONSTRATIVO',
  SET_PERFIL_CARTEIRA: 'RATING_THEME/SET_PERFIL_CARTEIRA'
}

// Reducer
const initialState = {
  grauInformalidadeVendas: null,
  confiabilidadeDemonstrativosFinanceiros: null,
  perfilCarteira: null
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.BOOTSTRAP: {
      return {
        ...state,
        ...action.payload
      }
    }
    case Types.SET_GRAU_INFORMALIDADE_VENDAS:
      return {
        ...state,
        grauInformalidadeVendas: action.payload
      }
    case Types.SET_CONFIABILIDADE_DEMONSTRATIVO:
      return {
        ...state,
        confiabilidadeDemonstrativosFinanceiros: action.payload
      }
    case Types.SET_PERFIL_CARTEIRA:
      return {
        ...state,
        perfilCarteira: action.payload
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function bootstrapRating ({
  confiabilidadeDemonstrativosFinanceiros,
  grauInformalidadeVendas,
  perfilCarteira
}) {
  return {
    type: Types.BOOTSTRAP,
    payload: {
      confiabilidadeDemonstrativosFinanceiros,
      grauInformalidadeVendas,
      perfilCarteira
    }
  }
}

export function setPerfilCarteiraRating (perfilCarteira) {
  return {
    type: Types.SET_PERFIL_CARTEIRA,
    payload: perfilCarteira
  }
}

export function setConfiabilidadeDemonstrativoRating (
  confiabilidadeDemonstrativosFinanceiros
) {
  return {
    type: Types.SET_CONFIABILIDADE_DEMONSTRATIVO,
    payload: confiabilidadeDemonstrativosFinanceiros
  }
}

export function setGrauInformalidadeVendasRating (grauInformalidadeVendas) {
  return {
    type: Types.SET_GRAU_INFORMALIDADE_VENDAS,
    payload: grauInformalidadeVendas
  }
}

export function resetRating () {
  return {
    type: Types.RESET
  }
}

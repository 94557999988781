import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { MdTrendingDown } from 'react-icons/md'

import 'react-toastify/dist/ReactToastify.css'
import './highlights.css'

import Sidebar from '../../components/sidebar'
import PageTitle from '../../components/pageTitle'
import * as SidebarActions from '../../store/actions/sidebar'
import Footer from '../../components/footer'

import TableAtivo from './tables/ativo'
import TablePassivo from './tables/passivo'
import TableDRE from './tables/dre'
import TableIndicadores from './tables/indicadores'
import TableHighLight from './tables/highlights'
import TableFluxoCaixa from './tables/fluxo_caixa'
import TableCaixa from './tables/caixa'

class Highlights extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(7)
  }

  clickSave () {
    Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(result => {
      if (!result.dismiss) {
        // this.props.history.push('/passivo');
      }
    })
  }

  render () {
    const { ativo, dados, dre, passivo, highlights } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title={dados.nome_empresa}
              icon={MdTrendingDown}
              btnSalvar={false}
              redux_data={ativo}
            />
            <div
              className='bodyContent'
              id='pageHighlight'
              style={{ paddingBottom: '1em', marginTop: '70px' }}
            >
              <div>
                <h3>Highlights</h3>
                <div className='col-12 tables-high row justify-content-start'>
                  {ativo.total && ativo.total.length > 0 && (
                    <TableAtivo id='tableAtivo' />
                  )}
                  <div className='table-divisor' />
                  {passivo.total && passivo.total.length > 0 && (
                    <TablePassivo id='tablePassivo' />
                  )}
                  {dre.total && dre.total.length > 0 && (
                    <TableDRE id='tableDRE' />
                  )}
                  <div className='table-divisor' />
                  {dre.total && dre.total.length > 0 && highlights && (
                    <TableIndicadores id='tableIndicadores' />
                  )}
                </div>
              </div>

              <div className='table-divider'>
                <h3>Highlights Corporate</h3>
                <div className='col-12 tables-high row justify-content-start'>
                  {dre.total && dre.total.length > 0 && highlights && (
                    <TableHighLight />
                  )}
                  <div className='table-divisor' />
                  {dre.total && dre.total.length > 0 && highlights && (
                    <TableFluxoCaixa />
                  )}
                </div>
              </div>

              <div className='table-divider'>
                <h3>Geração de Caixa</h3>
                <div className='col-12 tables-high row justify-content-start'>
                  {dre.total && dre.total.length > 0 && highlights && (
                    <TableCaixa />
                  )}
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ativo: state.ativo,
  passivo: state.passivo,
  dados: state.dados,
  sidebar: state.sidebar,
  parceiros: state.parceiros,
  dre: state.dre,
  fundos: state.fundos,
  mutacao: state.mutacao,
  highlights: state.highlights
})

const mapDispatchToProps = dispatch => ({
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Highlights)

import React from 'react'
import ReactDOM from 'react-dom'
import HttpsRedirect from 'react-https-redirect'

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

ReactDOM.render(
  <HttpsRedirect>
    <App />
  </HttpsRedirect>,
  document.getElementById('root')
)

export const getInitialData = data => {
  const categories = Object.fromEntries(
    Object.values(data.map)
      .flat()
      .map(categoria => {
        const id = categoria
        return [id, { id, content: categoria }]
      })
  )

  const columns = Object.fromEntries(
    Object.entries(data.map).map(([categoria, items]) => {
      const id = categoria
      const categoryIds = items
      return [id, { id, title: categoria, categoryIds }]
    })
  )

  const missingCategories = data.missing.reduce((obj, value) => {
    obj[value] = { id: value, content: value }
    return obj
  }, {})

  return {
    categories: {
      ...categories,
      ...missingCategories
    },
    columns: {
      ...columns,
      'Não classificado': {
        id: 'Não classificado',
        title: 'Não classificado',
        categoryIds: data.missing
      }
    },
    columnOrder: Object.keys(columns).concat('Não classificado')
  }
}

import React from 'react'
import CurrencyInput from 'react-currency-input'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { MdRemoveCircle } from 'react-icons/md'

import * as FundosActions from './../../store/actions/fundos'

const FundoItem = props => {
  const { fundo, keyItem, dispatch, type } = props

  return (
    <div className='row justify-content-start block-line no-gutters fundo-item'>
      <div className='col-4 label-instituicao text-right'>
        <input
          type='text'
          value={fundo.instituicao}
          onChange={async event => {
            await dispatch(
              FundosActions.updateString(
                keyItem,
                event.target.value,
                type,
                'instituicao'
              )
            )
          }}
          style={{ width: '140px' }}
        />
      </div>
      <div
        className='input-group col-4 label-total'
        style={{ position: 'relative', top: '-4px', padding: '4px 0' }}
      >
        <div className='input-group-prepend'>
          <span className='input-group-text'>R$</span>
        </div>

        <CurrencyInput
          value={fundo.total}
          thousandSeparator='.'
          allowNegative
          precision='0'
          className='inputCurrency input-money'
          onChange={async (valueComma, valueDot, event) => {}}
          onBlur={async event => {
            const rawValue = event.target.value // Always string
            const valueNumber =
              rawValue === '' || rawValue === '-'
                ? '0'
                : rawValue.replace(/\D/g, '')
            await dispatch(
              FundosActions.updateSimpleData(keyItem, valueNumber, type)
            )
            await dispatch(FundosActions.sumTotal())
          }}
          style={{ maxWidth: '100px' }}
        />
      </div>
      <div className='col-3 label-obs'>
        <input
          type='text'
          value={fundo.obs}
          onChange={async event => {
            await dispatch(
              FundosActions.updateString(
                keyItem,
                event.target.value,
                type,
                'obs'
              )
            )
          }}
        />
      </div>
      <div className='col-1' style={{ paddingLeft: '10px' }}>
        <button
          className='btn btn-outline-primary btn-sm'
          onClick={async () => {
            // event.preventDefault();
            Swal.fire({
              title: 'Excluir este Item',
              text: 'Deseja realmente excluir este item ?',
              showCancelButton: true,
              confirmButtonColor: '#535ad0',
              cancelButtonColor: '#262b88',
              icon: 'warning'
            }).then(async result => {
              if (result.value) {
                await dispatch(FundosActions.removeItem(type, keyItem))
                await dispatch(FundosActions.sumTotal())
              }
            })
          }}
        >
          <MdRemoveCircle style={{ position: 'relative', top: '-3px' }} />
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  fundos: state.fundos
})

export default connect(mapStateToProps)(FundoItem)

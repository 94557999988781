import React from 'react'
import { connect } from 'react-redux'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableCaixa = props => {
  const { dados, highlights } = props

  const caixa = highlights.geracao_caixa_livre

  const CaixaContent = [
    {
      name: 'EBITDA',
      type: 'highlight',
      value: index =>
        caixa.ebitda &&
        caixa.ebitda[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Desp. Financeira Paga',
      type: 'normal',
      value: index =>
        caixa.despesa_financeira_paga &&
        (caixa.despesa_financeira_paga[index] < 0
          ? caixa.despesa_financeira_paga[index]
          : caixa.despesa_financeira_paga[index] * -1
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Parcela Corrente Emp. Longo Prazo',
      type: 'normal',
      value: index =>
        caixa.parcela_corrente_emprestimo_lp &&
        caixa.parcela_corrente_emprestimo_lp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Imposto de Renda',
      type: 'normal',
      value: index =>
        caixa.ir &&
        caixa.ir[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Dividendos',
      type: 'normal',
      value: index =>
        caixa.dividendos &&
        caixa.dividendos[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Geração de Caxa Livre',
      type: 'highlight',
      value: index =>
        caixa.geracao_caixa_livre &&
        caixa.geracao_caixa_livre[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Fundos Intragrupo',
      type: 'normal',
      value: index =>
        caixa.fundos_intragrupo &&
        caixa.fundos_intragrupo[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Capitalização/Minoritários',
      type: 'normal',
      value: index =>
        caixa.capitalizacao_minoritarios &&
        caixa.capitalizacao_minoritarios[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Suporte (Cash Drain)',
      type: 'highlight',
      value: index =>
        caixa.cash_drain &&
        caixa.cash_drain[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa Disponível para Capex',
      type: 'highlight',
      value: index =>
        caixa.caixa_disponivel_para_capex &&
        caixa.caixa_disponivel_para_capex[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Ativo Fixo',
      type: 'normal',
      value: index =>
        caixa.ativo_fixo &&
        caixa.ativo_fixo[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Investimentos',
      type: 'normal',
      value: index =>
        caixa.investimentos &&
        caixa.investimentos[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Empréstimos de Longo Prazo',
      type: 'normal',
      value: index =>
        caixa.emprestimos_lp &&
        caixa.emprestimos_lp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Saldo das Inversões Permanentes',
      type: 'highlight',
      value: index =>
        caixa.saldo_inversoes_permanentes &&
        caixa.saldo_inversoes_permanentes[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa após Capex',
      type: 'highlight',
      value: index =>
        caixa.caixa_apos_capex &&
        caixa.caixa_apos_capex[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Necessidade Adicional de Giro',
      type: 'normal',
      value: index =>
        caixa.necessidade_adicional_giro &&
        caixa.necessidade_adicional_giro[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa após Crescimento',
      type: 'highlight',
      value: index =>
        caixa.caixa_apos_crescimento &&
        caixa.caixa_apos_crescimento[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Parcelamento de Tributos',
      type: 'normal',
      value: index =>
        caixa.parcelamento_tributos &&
        caixa.parcelamento_tributos[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Ganho e Perdas Não Operacionais',
      type: 'normal',
      value: index =>
        caixa.ganhos_perdas_nao_operacionais &&
        caixa.ganhos_perdas_nao_operacionais[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Bens para Venda',
      type: 'normal',
      value: index =>
        caixa.bens_para_venda &&
        caixa.bens_para_venda[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Despesas Antecipadas',
      type: 'normal',
      value: index =>
        caixa.despesas_antecipadas &&
        caixa.despesas_antecipadas[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Resultado de Exercícios Futuros',
      type: 'normal',
      value: index =>
        caixa.resultado_exercicios_futuros &&
        caixa.resultado_exercicios_futuros[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Ajustes Patrimônio Líquido',
      type: 'normal',
      value: index =>
        caixa.ajustes_patrimonio_liquido &&
        caixa.ajustes_patrimonio_liquido[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Diversos Não Operacionais - CP',
      type: 'normal',
      value: index =>
        caixa.diversos_nao_operacionais_cp &&
        caixa.diversos_nao_operacionais_cp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'DIversos Não Operacionais - LP',
      type: 'normal',
      value: index =>
        caixa.diversos_nao_operacionais_lp &&
        caixa.diversos_nao_operacionais_lp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Usos e Fontes Não Operacionais',
      type: 'highlight',
      value: index =>
        caixa.usos_fontes_nao_operacionais &&
        caixa.usos_fontes_nao_operacionais[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Geração de Caixa no Período',
      type: 'highlight',
      value: index =>
        caixa.geracao_caixa_no_periodo &&
        caixa.geracao_caixa_no_periodo[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Duplicatas Descontadas',
      type: 'normal',
      value: index =>
        caixa.duplicatas_descontadas &&
        caixa.duplicatas_descontadas[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Emp Curto Prazo (Reais)',
      type: 'normal',
      value: index =>
        caixa.emprestimo_cp_reais &&
        caixa.emprestimo_cp_reais[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Emp Curto Prazo (moeda estrangeira)',
      type: 'normal',
      value: index =>
        caixa.emprestimo_cp_estrangeiro &&
        caixa.emprestimo_cp_estrangeiro[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'ACC/ACE',
      type: 'normal',
      value: index =>
        caixa.acc_ace &&
        caixa.acc_ace[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Importação',
      type: 'normal',
      value: index =>
        caixa.importacao &&
        caixa.importacao[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Empréstimos de Curto Prazo',
      type: 'highlight',
      value: index =>
        caixa.emprestimos_cp &&
        caixa.emprestimos_cp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa Final',
      type: 'highlight total',
      value: index =>
        caixa.caixa_final &&
        caixa.caixa_final[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table
      className='table table-sm table-striped table-root col-12'
      style={{ maxWidth: '100%' }}
    >
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>Fluxo de Caixa</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {CaixaContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  highlights: state.highlights
})

export default connect(mapStateToProps)(TableCaixa)

export function updateSimpleData (key, value, typeFundo) {
  return {
    type: 'FUNDOS_UPDATE_SIMPLE_DATA',
    key,
    value,
    typeFundo
  }
}

export function updateString (key, value, typeFundo, typeString) {
  return {
    type: 'FUNDOS_UPDATE_STRING',
    key,
    value,
    typeFundo,
    typeString
  }
}

export function sumTotal (typeString) {
  return {
    type: 'SUM_TOTAL',
    typeString
  }
}

export function addItem (typeItem, name) {
  return {
    type: 'ADD_ITEM',
    typeItem,
    name
  }
}

export function removeItem (typeItem, index) {
  return {
    type: 'REMOVE_ITEM',
    typeItem,
    index
  }
}

export function bootstrap (data) {
  return {
    type: 'BOOTSTRAP_FUNDOS',
    data
  }
}

import JSON_TABLE from './../../assets/table-mutacao.json'

const progress = { ...JSON_TABLE }
Object.keys(progress).forEach(function (item) {
  progress[item] = false
})

delete progress.total

const INITIAL_STATE = {
  items: JSON_TABLE,
  progress,
  progress_show_tooltip: false
}

// FILL WITH {value: 0, percent: 0} if item has a different elements in array
const fillIfsEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill({ value: 0, percent: 0 })
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = { value: 0, percent: 0 }
      }
    }
    return originValue
  }
  return originValue
}

// FILL WITH 0 i item has different elements in array
const totalIfEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill(0)
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = 0
      }
    }
    return originValue
  }
  return originValue
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'BOOTSTRAP_MUTACAO') {
    if (action.data) {
      const patrimonioLiquidoFinal = [
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.patrimonio_liquido_inicial,
            action.total_data_el
          ),
          id_back: 'patrimonio_liquido_inicial',
          item: 'Patrimônio Líquido Inicial',
          type: '+',
          noedit: true,
          id: 0
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.lucro_liquido_exercicio,
            action.total_data_el
          ),
          id_back: 'lucro_liquido_exercicio',
          item: 'Lucro Líquido do Exercício',
          type: '+',
          noedit: true,
          id: 1
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.entrada_de_capital,
            action.total_data_el
          ),
          id_back: 'entrada_de_capital',
          item: 'Entrada de Capital',
          type: '+',
          id: 2
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.correcao_monetaria,
            action.total_data_el
          ),
          id_back: 'correcao_monetaria',
          item: 'Correção Monetária',
          type: '+',
          id: 3
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.ajuste_exercicios_anteriores,
            action.total_data_el
          ),
          id_back: 'ajuste_exercicios_anteriores',
          item: 'Ajuste de Exercícios Anteriores',
          type: '+',
          id: 4
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.incentivos_fiscais_doacoes,
            action.total_data_el
          ),
          id_back: 'incentivos_fiscais_doacoes',
          item: 'Incentivos Fiscais/ Doações',
          type: '+',
          id: 5
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.dividendos,
            action.total_data_el
          ),
          id_back: 'dividendos',
          item: 'Dividendos',
          type: '+',
          id: 6
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido_final.reavaliacao_ativos,
            action.total_data_el
          ),
          id_back: 'reavaliacao_ativos',
          item: 'Reavaliação de Ativos',
          type: '+',
          id: 7
        }
      ]

      const prevState = {
        ...state,
        items: {
          ...state.items,
          patrimonio_liquido_final: {
            ...state.items.patrimonio_liquido_final,
            items: patrimonioLiquidoFinal,
            total: totalIfEmpty(
              action.data.patrimonio_liquido_final.total,
              action.total_data_el
            )
          },
          dif_patrimonio_liquido: {
            total: totalIfEmpty(
              action.data.dif_patrimonio_liquido.total,
              action.total_data_el
            ),
            items: []
          },
          total: totalIfEmpty(
            action.data.patrimonio_liquido_final.total,
            action.total_data_el
          )
        },
        total: totalIfEmpty(
          action.data.patrimonio_liquido_final.total,
          action.total_data_el
        )
      }

      return prevState
    } else {
      const prevState = { ...state }
      prevState.items.patrimonio_liquido_final.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.patrimonio_liquido_final.total = Array(
        action.total_data_el
      ).fill(0)
      prevState.items.dif_patrimonio_liquido.total = Array(
        action.total_data_el
      ).fill(0)
      prevState.items.total = Array(action.total_data_el).fill(0)
      prevState.total = Array(action.total_data_el).fill(0)

      return prevState
    }
  }

  if (action.type === 'MUTACAO_UPDATE_SIMPLE_DATA') {
    return {
      ...state,
      [action.key]: action.value,
      progress: {
        ...state.progress,
        [action.key]: true
      }
    }
  }

  if (action.type === 'MUTACAO_UPDATE_TABLE_EXCEL') {
    return {
      ...state,
      progress: {
        ...state.progress,
        [action.key_name_items]: true
      },
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      value: action.value
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'MUTACAO_SUM_VALUES_FROM_ELEMENT') {
    let total = 0

    state.items[action.key_name_items].items.map(item => {
      const value = item.datas[action.key_data].value
      total += value
      return null
    })

    const value = parseFloat(action.value)
    const percent = total !== 0 ? (value * 100) / total : 0

    return {
      ...state,
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          total: state.items[action.key_name_items].total.map(
            (itemTotal, keyTotal) =>
              keyTotal === action.key_data ? total : itemTotal
          ),
          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      percent
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'MUTACAO_SUM_ALL_VALUES_BY_DATE') {
    const object2Array = Object.entries(state.items)
    object2Array.pop() // last element is total obj -> is necessary remove them
    let total = 0

    object2Array.map(item => {
      if (item[0] !== 'dif_patrimonio_liquido') {
        total += item[1].total[action.key_data]
      }

      return null
    })

    return {
      ...state,
      total: state.total.map((value, key) =>
        key === action.key_data ? total : value
      ),
      items: {
        ...state.items,
        total: state.total.map((value, key) =>
          key === action.key_data ? total : value
        )
      }
    }
  }

  // this action is for recalculate all porcentage for this respective date column (by: action.keydata)
  if (action.type === 'MUTACAO_PORCENTAGE_ALL_ELEMENTS') {
    const total = state.total[action.key_data]
    const porcentageCalc = value => {
      return total === 0 ? 0 : ((value * 100) / total).toFixed(0)
    }
    const newStateItems = Object.keys(state.items)
    newStateItems.pop() // last key is super total

    newStateItems.forEach((key, item) => {
      state.items[key].items.map(item => {
        return item.datas.map((data, key) => {
          if (key === action.key_data) {
            data.percent = porcentageCalc(data.value) + '%'
          } else {
            return data
          }
          return null
        })
      })
      return null
    })

    return {
      ...state,
      items: state.items
    }
  }

  if (action.type === 'MUTACAO_UPDATE_PROGRESS') {
    return {
      ...state,
      progress: {
        ...progress,
        [action.keyName]: true
      }
    }
  }

  // SUM ALL SUB TOTAL FROM SPECIFIC ITEMS WITHOUT VALUE
  if (action.type === 'SUM_SUB_TOTAL_WITHOUT_VALUE') {
    const newState = JSON.parse(JSON.stringify(state.items))

    const newStateItems = Object.keys(newState)

    newStateItems.pop() // last key is super total
    const superTotal = Array(action.datas.length).fill(0)

    // foreach for each ITEM
    newStateItems.forEach((key, item) => {
      const items = newState[key].items
      const total = Array(action.datas.length).fill(0) // new total with zeros

      // foreach for each sub ITEM
      items.forEach(item => {
        // foreach for each data
        total.forEach((totalValue, keyTotal) => {
          if (item.datas === undefined) {
            item.datas = Array(action.datas.length)
              .fill()
              .map(u => ({
                value: 0,
                percent: 0
              }))
          }

          // check if datas is defined and array has elements
          if (item.datas !== undefined && item.datas.length > 0) {
            total[keyTotal] +=
              item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
            superTotal[keyTotal] +=
              item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
          }
        })
      })

      items.total = total
    })

    console.groupEnd()

    return {
      ...state
      // items: new_state,
      // total: superTotal
    }
  }

  if (action.type === 'UPDATE_DATE') {
    const totalDatas = action.value
    const modelItem = { value: 0, percent: 0 }
    const newStateItems = Object.entries(state.items)
    newStateItems.pop()
    const totalArray = new Array(totalDatas).fill(0)
    const totalItem = totalArray.map(item => modelItem)

    const newState = JSON.parse(JSON.stringify(state.items))

    newStateItems.forEach((key, item) => {
      const items = newState[key[0]].items
      const total = totalArray

      items.forEach(item => {
        item.total = totalArray
        total.forEach((totalValue, keyTotal) => {
          item.datas = totalItem
        })
      })

      newState[key[0]].total = totalArray
    })
    return {
      ...state,
      items: newState,
      total: totalArray
    }
  }

  if (action.type === 'REMOVE_DATE_MUTACAO') {
    const dataIndex = action.value.id

    return {
      ...state,
      items: {
        ...state.items,
        patrimonio_liquido_final: {
          ...state.items.patrimonio_liquido_final,
          items: state.items.patrimonio_liquido_final.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.patrimonio_liquido_final.total &&
            state.items.patrimonio_liquido_final.total.filter(
              (item, index) => index !== dataIndex
            )
        },

        total:
          state.total &&
          state.total.filter((item, index) => index !== dataIndex)
      },
      total:
        state.total && state.total.filter((item, index) => index !== dataIndex)
    }
  }

  if (action.type === 'ADD_NEW_DATE_MUTACAO') {
    const dateBase = { value: 0, percent: 0 }
    const totalBase = 0

    return {
      ...state,
      items: {
        ...state.items,
        dif_patrimonio_liquido: {
          ...state.items.dif_patrimonio_liquido,
          total: [...state.items.dif_patrimonio_liquido.total, 0]
        },
        patrimonio_liquido_final: {
          ...state.patrimonio_liquido_final,
          items: state.items.patrimonio_liquido_final.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.patrimonio_liquido_final.total, totalBase]
        },

        total: state.total && [...state.total, totalBase]
      },
      total: state.total && [...state.total, totalBase]
    }
  }

  if (action.type === 'DIFERENCA_PATRIMONIO_LIQUIDO') {
    const totalPassivo = action.total_passivo
    const totalMutacao = state.total[action.key_data]
    const totalFinal = totalPassivo - totalMutacao

    return {
      ...state,
      items: {
        ...state.items,
        dif_patrimonio_liquido: {
          ...state.items.dif_patrimonio_liquido,
          total: state.items.dif_patrimonio_liquido.total.map(
            (totalTemp, key) =>
              key === action.key_data ? totalFinal : totalTemp
          )
        }
      }
    }
  }

  if (action.type === 'PATRIMONIO_LIQUIDO_INICIAL') {
    const patrimonioLiquidoInicial =
      action.key_data === 0
        ? state.items.patrimonio_liquido_final.items[0].datas[action.key_data]
          .value
        : state.items.patrimonio_liquido_final.total[action.key_data - 1]

    return {
      ...state,
      items: {
        ...state.items,
        patrimonio_liquido_final: {
          ...state.items.patrimonio_liquido_final,
          items: state.items.patrimonio_liquido_final.items.map((item, key) =>
            key === 0
              ? {
                ...item,
                datas: item.datas.map((data, datakey) =>
                  datakey === action.key_data
                    ? {
                      ...data,
                      value: patrimonioLiquidoInicial
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }

    // console.group('total datas');
    // console.log('total datas', total_datas);
    // console.log('patrimonio liquido inicial', patrimonio_liquido_inicial);
    // console.log('key_data', action.key_data);
    // console.log('dados', action.total_datas);
    // console.log('tudo', action);
    // console.groupEnd();
  }

  if (action.type === 'LUCRO_LIQUIDO_EXERCICIO') {
    console.log('ver dre -> origem', action.dre)

    let dreValue = action.dre

    if (action.dre === undefined) {
      dreValue = Array(action.key_data).fill(0)
    }

    const totalLucroLiquidoExercicio = dreValue[action.key_data]

    return {
      ...state,
      items: {
        ...state.items,
        patrimonio_liquido_final: {
          ...state.items.patrimonio_liquido_final,
          items: state.items.patrimonio_liquido_final.items.map((item, key) =>
            key === 1
              ? {
                ...item,
                datas: item.datas.map((data, datakey) =>
                  datakey === action.key_data
                    ? {
                      ...data,
                      value: totalLucroLiquidoExercicio
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'PATRIMONIO_INICIAL_ENABLEDEDIT') {
    console.log('entrou enabled')
    if (state.items.patrimonio_liquido_final.items[0].noedit === true) {
      return {
        ...state,
        items: {
          ...state.items,
          patrimonio_liquido_final: {
            ...state.items.patrimonio_liquido_final,
            items: state.items.patrimonio_liquido_final.items.map((item, key) =>
              key === 0
                ? {
                  ...item,
                  noedit: false
                }
                : item
            )
          }
        }
      }
    }
  }

  return state
}

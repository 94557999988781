import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import DatePicker, { registerLocale } from 'react-datepicker'

import { MdRemoveCircle, MdEdit } from 'react-icons/md'

import ptbr from 'date-fns/locale/pt'

import 'react-datepicker/dist/react-datepicker.css'

import * as DadosActions from './../store/actions/dados_datas'
import * as AtivoActions from './../store/actions/ativo'
import * as PassivoActions from './../store/actions/passivo'
import * as MutacaoActions from './../store/actions/mutacao'
import * as DREActions from './../store/actions/dre'

import { changeDate, deleteDate } from '../services'

registerLocale('ptbr', ptbr)

const DadosTableRow = props => {
  const {
    data,
    auditado,
    consolidado,
    combinado,
    dados,
    position,
    changeBalanco,
    changeAuditado,
    changeCombinado,
    changeConsolidado,
    removeData,
    el,
    isGroup
  } = props

  const styleFix = {
    padding: '0',
    margin: '0',
    height: '30px'
  }

  return (
    <div
      className='col-12'
      style={{
        paddingLeft: '0px',
        paddingBottom: '0px',
        marginLeft: '8px',
        height: '35px',
        borderBottom: '1px solid #31358c14',
        marginBottom: '3px'
      }}
    >
      <div className='row justify-content-start'>
        <div
          className='col-3'
          style={{ ...styleFix, margin: 0, height: '30px' }}
        >
          <div className='form-group' style={{ ...styleFix, margin: 0 }}>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={data}
              locale={ptbr}
              className='form-control form-control-sm date-picker-block'
              onChange={value => {
                const datasIguais = false
                dados.datas.forEach(dataTemp => {
                  const dataTyped = new Date(value)
                  const dataBase = new Date(dataTemp.data)

                  const data1 =
                    dataTyped.getFullYear() +
                    '/' +
                    dataTyped.getMonth() +
                    '/' +
                    dataTyped.getUTCDate()

                  const data2 =
                    dataBase.getFullYear() +
                    '/' +
                    dataBase.getMonth() +
                    '/' +
                    dataBase.getUTCDate()

                  if (data1 === data2) {
                    // Swal.fire('Datas Iguais', 'Existem datas iguais no período', 'error');
                    // datas_iguais = true;
                  }
                })

                if (!datasIguais) {
                  changeBalanco(value, el)
                }
              }}
              defaultValue={dados.datas[position]}
              style={{ width: '100%' }}
              placeholder='data à selecionar'
              disabled={el.enabled}
            />
          </div>
        </div>

        <div className='col-auto' style={{ ...styleFix, marginLeft: '10px' }}>
          <div className='form-group' style={styleFix}>
            <label className='label'>Auditado ?</label>
            <div style={{ position: 'relative', top: '-2px' }}>
              <input
                type='checkbox'
                className='form-check-input'
                defaultChecked={auditado}
                onClick={value => {
                  changeAuditado(value, el)
                }}
              />
              <span style={{ fontSize: '12px' }}>sim</span>
            </div>
          </div>
        </div>

        <div className='col-auto' style={{ ...styleFix, marginLeft: '10px' }}>
          <div className='form-group' style={styleFix}>
            <label className='label'>Consolidado ?</label>
            <div style={{ position: 'relative', top: '-2px' }}>
              <input
                type='checkbox'
                className='form-check-input'
                defaultChecked={consolidado}
                onClick={value => {
                  changeConsolidado(value, el)
                }}
              />
              <span style={{ fontSize: '12px' }}>sim</span>
            </div>
          </div>
        </div>

        <div className='col-auto' style={{ ...styleFix, marginLeft: '10px' }}>
          <div className='form-group' style={styleFix}>
            <label className='label'>Combinado ?</label>
            <div style={{ position: 'relative', top: '-2px' }}>
              <input
                type='checkbox'
                className='form-check-input'
                defaultChecked={combinado}
                onClick={value => {
                  changeCombinado(value, el)
                }}
              />
              <span style={{ fontSize: '12px' }}>sim</span>
            </div>
          </div>
        </div>

        <div className='col-auto' style={styleFix}>
          <button
            className='btn btn-sm btn-outline-primary'
            style={{
              border: 'none',
              fontSize: '20px',
              padding: '0px 20px',
              marginLeft: '10px',
              marginTop: '2px'
            }}
            onClick={event => {
              event.preventDefault()
              if (!el.enabled) {
                Swal.fire({
                  title: 'Excluir Data',
                  text: 'Deseja realmente excluir este item ?',
                  showCancelButton: true,
                  confirmButtonColor: '#dc3545',
                  cancelButtonColor: '#262b88',
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Excluir',
                  icon: 'warning'
                }).then(result => {
                  if (result.value) {
                    removeData(el, dados.datas.length - 1)
                  }
                })

                return
              }

              const year = data.getFullYear()
              const month = (data.getMonth() + 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
              const day = data.getDate().toLocaleString('en-US', {
                minimumIntegerDigits: 2
              })
              const hiddenDate = `${year}${month}${day}`

              event.preventDefault()
              Swal.fire({
                title: 'Tem certeza que deseja remover esta data?',
                html: `
                  <div>
                    <p>Assim que remover esta data todas as informações atreladas à ela serão EXCLUÍDAS.</p>
                    <input id="key" placeholder="Senha de permissão" type="text" class="swal2-input">
                  </div>
                `,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                showCancelButton: true,
                confirmButtonColor: '#dc3545',
                cancelButtonColor: '#262b88',
                icon: 'warning',
                preConfirm: () => {
                  const key = document.getElementById('key')?.value

                  if (!key) {
                    Swal.showValidationMessage(
                      'É obrigatório informar a senha de permissão'
                    )
                    return
                  }

                  return { key, data }
                }
              }).then(async ({ isConfirmed, value }) => {
                if (!isConfirmed) {
                  return
                }
                Swal.showLoading()
                try {
                  await deleteDate({
                    data: hiddenDate,
                    authKey: value.key,
                    id: dados.cnpj,
                    isGroup
                  })
                  document.location.reload()
                } catch (err) {
                  const message =
                    err.response?.data && Array.isArray(err.response.data)
                      ? err.response.data.join('<br/>')
                      : err.message
                  console.error(err)
                  Swal.fire(
                    'Ops! Não foi possível remover a data',
                    message,
                    'error'
                  )
                } finally {
                  Swal.hideLoading()
                }
              })
            }}
          >
            <MdRemoveCircle style={{ position: 'relative', top: '-3px' }} />
          </button>
        </div>
        {el.enabled && (
          <div className='col-auto' style={styleFix}>
            <button
              className='btn btn-sm btn-outline-primary'
              style={{
                border: 'none',
                fontSize: '20px',
                padding: '0px 20px',
                marginLeft: '10px',
                marginTop: '2px'
              }}
              onClick={event => {
                const year = data.getFullYear()
                const month = (data.getMonth() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2
                })
                const day = data.getDate().toLocaleString('en-US', {
                  minimumIntegerDigits: 2
                })
                const initialDate = `${year}-${month}-${day}`
                const hiddenDate = `${year}${month}${day}`

                event.preventDefault()
                Swal.fire({
                  title: 'Deseja realmente editar esta data?',
                  html: `
                  <div>
                    <p>Assim que editar esta data todas as informações atreladas à ela serão modificadas.</p>
                    <input id="key" placeholder="Senha de permissão" type="text" class="swal2-input">
                    <input id="data" type="date" value="${initialDate}" class="swal2-input">
                  </div>
                `,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Editar',
                  showCancelButton: true,
                  confirmButtonColor: '#dc3545',
                  cancelButtonColor: '#262b88',
                  icon: 'warning',
                  preConfirm: () => {
                    const key = document.getElementById('key')?.value
                    const data = document.getElementById('data')?.value

                    if (!key) {
                      Swal.showValidationMessage(
                        'É obrigatório informar a senha de permissão'
                      )
                      return
                    }

                    if (!data) {
                      Swal.showValidationMessage(
                        'É obrigatório informar a data'
                      )
                      return
                    }

                    return { key, data }
                  }
                }).then(async ({ isConfirmed, value }) => {
                  if (!isConfirmed) {
                    return
                  }
                  const dataNova = value.data.replace(/-/g, '')
                  Swal.showLoading()
                  try {
                    await changeDate({
                      dataNova,
                      dataAntiga: hiddenDate,
                      authKey: value.key,
                      id: dados.cnpj,
                      isGroup
                    })
                    document.location.reload()
                  } catch (err) {
                    const message =
                      err.response?.data && Array.isArray(err.response.data)
                        ? err.response.data.join('<br/>')
                        : err.message
                    console.error(err)
                    Swal.fire(
                      'Ops! Não foi possível editar a data',
                      message,
                      'error'
                    )
                  } finally {
                    Swal.hideLoading()
                  }
                })
              }}
            >
              <MdEdit style={{ position: 'relative', top: '-3px' }} />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  changeBalanco: (data, item) =>
    dispatch(DadosActions.changeBalanco(data, item)),
  changeAuditado: (data, item) =>
    dispatch(DadosActions.changeAuditado(data, item)),
  changeConsolidado: (data, item) =>
    dispatch(DadosActions.changeConsolidado(data, item)),
  changeCombinado: (data, item) =>
    dispatch(DadosActions.changeCombinado(data, item)),
  removeData: (position, totalDates) => {
    dispatch(DadosActions.removeData(position))
    dispatch(AtivoActions.removeData(position, totalDates + 1))
    dispatch(PassivoActions.removeDataPassivo(position, totalDates + 1))
    dispatch(MutacaoActions.removeDataMutacao(position, totalDates + 1))
    dispatch(DREActions.removeDataDRE(position, totalDates + 1))
    // dispatch(AtivoActions.updateDate(total_dates))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DadosTableRow)

import React from 'react'
import { connect } from 'react-redux'

import './../item-currency/item-currency.css'

class TotalDRE extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dados: this.props.dados.datas,
      total: this.props.dre.total
    }

    this.props.dre.total =
      this.props.dre.total === undefined || this.props.dre.total.length === 0
        ? new Array(this.props.dados.datas.length).fill(0)
        : this.props.dre.total
  }

  render () {
    const { dados, dre } = this.props

    const getDate = date => {
      const d = new Date(date)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    return (
      <div className='ativoTotal'>
        <div className='row justify-content-center no-gutters'>
          <div className='col-10'>
            <div className='row justify-content-start'>
              <div className='col-auto row justify-content-start'>
                <div className='total-txt col-auto text-right'>Total</div>
                {dados.datas.map((date, keyData) => (
                  <div className='col-auto total-bloc' key={keyData}>
                    <div className='total-date'>{getDate(date.data)}</div>
                    <div className='total-secondLine'>
                      <span>R$</span>
                      <span>
                        {dre.items &&
                          dre.items.total &&
                          dre.items.total.length > 0 &&
                          dre.items.total[keyData] &&
                          dre.items.total[keyData].toLocaleString('pt-BR')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  dre: state.dre
})

export default connect(mapStateToProps)(TotalDRE)

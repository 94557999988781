import React from 'react'
import { connect } from 'react-redux'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableHighLights = props => {
  const { dados, highlights } = props

  const { highlights_corporate: highlightsCorporate } = highlights
  const high = highlightsCorporate.highlights

  const HighlightContent = [
    {
      name: 'Receitas Líquidas',
      type: 'normal',
      value: index =>
        high &&
        high.receita_liquida[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Δ%',
      type: 'normal',
      value: index => high && '' + high.delta_porcentagem[index]
    },
    {
      name: 'Res. Operacional',
      type: 'normal',
      value: index =>
        high &&
        high.res_operacional[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Margem Operacional (%)',
      type: 'normal',
      value: index => high && '' + high.margem_operacional[index]
    },
    {
      name: 'Res. Oper. Líquido',
      type: 'normal',
      value: index =>
        high &&
        high.res_operacional_liquido[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Margem Oper. Liquida (%)',
      type: 'normal',
      value: index => high && '' + high.margem_operacional_liquida[index]
    },
    {
      name: 'Lucro Líquido',
      type: 'normal',
      value: index =>
        high &&
        high.lucro_liquido[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Margem Líquida (%)',
      type: 'normal',
      value: index => high && ' ' + high.margem_liquida[index]
    },

    {
      name: 'EBITDA',
      type: 'normal',
      value: index =>
        high &&
        high.ebitda[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'EBITDA/Vendas Líquidas (%)',
      type: 'normal',
      value: index => high && '' + high.ebitda_sobre_vendas_liquidas[index]
    },
    {
      name: 'PL Tangível',
      type: 'normal',
      value: index =>
        high &&
        high.pl_tangivel[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Entrada de Capital',
      type: 'normal',
      value: index =>
        high &&
        high.entrada_de_capital[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Capital de Giro Próprio',
      type: 'normal',
      value: index =>
        high &&
        high.capital_giro_proprio[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Capital Circulante Liquido (CCL)',
      type: 'normal',
      value: index =>
        high &&
        high.capital_circulante_liquido[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa + Aplicações',
      type: 'normal',
      value: index =>
        high &&
        high['caixa_+_aplicacoes'][index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Contas a receber (Clientes)',
      type: 'normal',
      value: index =>
        high &&
        high.contas_receber_clientes[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Estoques',
      type: 'normal',
      value: index =>
        high &&
        high.estoques[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Impostos a recuperar CP',
      type: 'normal',
      value: index =>
        high &&
        high.impostos_recuperar[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Partes Relacionadas (a receber LP)',
      type: 'normal',
      value: index =>
        high &&
        high.partes_relacionadas_receber_lp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Ativo Permanente',
      type: 'normal',
      value: index =>
        high &&
        high.ativo_permanente[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Fornecedores',
      type: 'normal',
      value: index =>
        high &&
        high.fornecedores[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Provisão IR/CS/Sociais/Tributárias',
      type: 'normal',
      value: index =>
        high &&
        high.provisao_ir_cs_sociais_tributarias[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Endividamento Bancário CP',
      type: 'normal',
      value: index =>
        high &&
        high.endividamento_bancario_cp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Endividamento Bancário Total',
      type: 'normal',
      value: index =>
        high &&
        high.endividamento_bancario_total[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Δ% Endiv Bancário Total (AxA)',
      type: 'normal',
      value: index =>
        high && '' + high.delta_endiv_bancario_total_anualizado[index]
    },
    {
      name: 'Endividamento líquido (NFD)',
      type: 'normal',
      value: index =>
        high &&
        high.endividamento_liquido_nfd[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'NFD em dias de vendas líquidas',
      type: 'normal',
      value: index => high && '' + high.nfd_em_dias_vendas_liquidas[index]
    },
    {
      name: 'NFD / Ebitda',
      type: 'normal',
      value: index => high && '' + high.nfd_sobre_ebitda[index]
    },
    {
      name: 'NFD CP / Ebitda',
      type: 'normal',
      value: index => high && '' + high.nfd_cp_sobre_ebitda[index]
    },
    {
      name: 'End. Oneroso Líquido',
      type: 'normal',
      value: index => high && '' + high.endividamento_oneroso_liquido[index]
    },
    {
      name: 'Desp. Fin/EBITDA (%)',
      type: 'normal',
      value: index => high && '' + high.desp_financeira_sobre_ebitda[index]
    },
    {
      name: 'Leverage',
      type: 'normal',
      value: index => high && '' + high.leverage[index]
    },
    {
      name: 'Liquidez Corrente',
      type: 'normal',
      value: index => high && '' + high.liquidez_corrente[index]
    },
    {
      name: 'ROIC',
      type: 'normal',
      value: index => high && high.roic[index] + ''
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table className='table table-sm table-striped col-6 table-root'>
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>Highlights</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {HighlightContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  highlights: state.highlights
})

export default connect(mapStateToProps)(TableHighLights)

import React from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'

import * as DadosActions from './../../store/actions/dados_datas'

import './pageTitle.css'
import 'react-toastify/dist/ReactToastify.css'

const PageTitle = props => {
  const { title, btnSalvar, dados, isGroup, subtitle } = props
  const { btnExternal } = props

  return (
    <div>
      <div
        className='pageTitle row justify-content-between no-gutters'
        style={
          dados.datas.length < 1
            ? { backgroundColor: '#141414', color: 'white' }
            : { backgroundColor: 'white', color: '#535ad0' }
        }
      >
        <div className='col-auto titlePage'>
          {title} {isGroup ? <span className='text-danger'> (GRUPO)</span> : ''}
        </div>

        {subtitle ? (
          <div
            className='col-auto'
            style={{ fontSize: '18px', paddingTop: '3px' }}
          >
            {subtitle}
          </div>
        ) : (
          dados.datas.length < 1 && (
            <div
              className='col-auto'
              style={{ fontSize: '18px', paddingTop: '3px' }}
            >
              É NECESSÁRIO ADICIONAR DATAS PARA SALVAR
            </div>
          )
        )}

        <div
          className='col-3 btn-group btn-group-sm'
          role='group'
          style={{ marginRight: '100px' }}
        >
          {btnSalvar !== false && (
            <button
              // disabled={isComplete()}
              className='btn btn-secondary btn-sm btn-salvar'
              onClick={() => {
                if (
                  dados.cnpj &&
                  dados.cnpj !== '**.***.***/****-**' &&
                  !isNaN(dados.cnpj)
                ) {
                  btnSalvar()
                } else {
                  toast('CNPJ inválido', {
                    className: 'toast-red',
                    bodyClassName: 'toast-body',
                    progressClassName: 'toast-progress',
                    position: 'top-right',
                    hideProgressBar: true,
                    style: {
                      borderRadius: '10px'
                    }
                  })
                }
              }}
              style={{ height: '40px' }}
              disabled={
                dados.cnpj !== '' && dados.datas && dados.datas.length < 1
              }
            >
              Salvar Informações
            </button>
          )}

          {btnExternal && btnExternal}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  updateSimpleData: (key, value) =>
    dispatch(DadosActions.updateSimpleData(key, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle)

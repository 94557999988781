import React from 'react'
import { connect } from 'react-redux'

import { FaPercentage } from 'react-icons/fa'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableDRE = props => {
  const { dre, dados } = props

  const porcentFromTotal = (value, index) => {
    const dreTotal = dre.items.receita_liquida.total[index]
    if (dreTotal === 0) {
      return 0
    }
    const total = (value / value) * 100
    return Math.round(parseFloat(total).toFixed(2))
  }

  const dreContent = [
    {
      name: 'Faturamento Bruto',
      type: 'highlight',
      porcent: index =>
        parseFloat(
          dre.items.receita_liquida.items[0].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          dre.items.receita_liquida.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Impostos / Incidentes / Deduções',
      type: 'normal',
      porcent: index => {
        return parseFloat(
          dre.items.receita_liquida.items[1].datas[index].percent +
            dre.items.receita_liquida.items[2].datas[index].percent
        ).toFixed(0)
      },
      value: index => {
        return parseFloat(
          dre.items.receita_liquida.items[1].datas[index].value +
            dre.items.receita_liquida.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },

    {
      name: 'Faturamento Líquido',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(dre.items.receita_liquida.total[index], index),
      value: index =>
        parseFloat(dre.items.receita_liquida.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Custos',
      type: 'normal',
      porcent: index =>
        parseFloat(
          dre.items.receita_liquida.total[index] === 0
            ? 0
            : (dre.items.lucro_bruto.items[0].datas[index].value * 100) /
              dre.items.receita_liquida.total[index]
        ).toFixed(0),
      value: index =>
        parseFloat(
          dre.items.lucro_bruto.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Depreciação / Amortização',
      type: 'normal',
      porcent: index =>
        parseFloat(dre.items.lucro_bruto.items[2].datas[index].percent).toFixed(
          0
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_bruto.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Lucro Bruto',
      type: 'highlight',
      porcent: index =>
        Math.round(
          dre.items.receita_liquida.total[index] === 0
            ? 0
            : (dre.items.lucro_bruto.total[index] * 100) /
              dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(dre.items.lucro_bruto.total[index]).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Despesas Adm. Oper',
      type: 'normal',
      porcent: index => {
        return parseFloat(
          (dre.items.receita_liquida.total[index] === 0
            ? 0
            : (dre.items.lucro_operacional.items[0].datas[index].value * 100) /
            dre.items.receita_liquida.total[index]) +
            (dre.items.receita_liquida.total[index] === 0
              ? 0
              : (dre.items.lucro_operacional.items[1].datas[index].value *
                100) /
              dre.items.receita_liquida.total[index])
        ).toFixed(0)
      },
      value: index => {
        return parseFloat(
          dre.items.lucro_operacional.items[0].datas[index].value +
            dre.items.lucro_operacional.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },

    {
      name: 'Outras desp. operacionais',
      type: 'normal',
      porcent: index =>
        Math.round(
          dre.items.receita_liquida.total[index] === 0
            ? 0
            : (dre.items.lucro_operacional.items[4].datas[index].value * 100) /
              dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_operacional.items[4].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Outras rec. operacionais',
      type: 'normal',
      porcent: index =>
        Math.round(
          dre.items.receita_liquida.total[index] === 0
            ? 0
            : (dre.items.lucro_operacional.items[5].datas[index].value * 100) /
              dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_operacional.items[5].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Lucro Operacional',
      type: 'highlight',
      porcent: index => {
        return Math.round(
          (dre.items.lucro_operacional.total[index] * 100) /
            dre.items.receita_liquida.total[index]
        )
      },
      value: index =>
        parseFloat(dre.items.lucro_operacional.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Despesa Financeira',
      type: 'normal',
      porcent: index =>
        Math.round(
          (dre.items.lucro_operacional_liquido.items[0].datas[index].value *
            100) /
            dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_operacional_liquido.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Receita Financeira',
      type: 'normal',
      porcent: index =>
        Math.round(
          (dre.items.lucro_operacional_liquido.items[2].datas[index].value *
            100) /
            dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_operacional_liquido.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Lucro Operacional Líquido',
      type: 'highlight',
      porcent: index =>
        Math.round(
          (dre.items.lucro_operacional_liquido.items[2].datas[index].value *
            100) /
            dre.items.lucro_operacional_liquido.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_operacional_liquido.total[index]
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Resultado não operacional',
      type: 'normal',
      porcent: index => {
        // console.log('resultado nao operacional', dre.items.lucro_liquido.items[0].datas[index].value);
        // console.log('total', dre.items.receita_liquida.total[index]);
        // console.log('RESULTADO FINAL', dre.items.lucro_liquido.items[0].datas[index].value * 100 / dre.items.lucro_operacional_liquido)

        return Math.round(
          (dre.items.lucro_liquido.items[0].datas[index].value * 100) /
            dre.items.receita_liquida.total[index]
        )
      },
      value: index =>
        parseFloat(
          dre.items.lucro_liquido.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Res. Contr/Colig/Eq. Patrimonial',
      type: 'normal',
      porcent: index =>
        Math.round(
          (dre.items.lucro_liquido.items[1].datas[index].value * 100) /
            dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(
          dre.items.lucro_liquido.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'IR/CS',
      type: 'normal',
      porcent: index =>
        parseFloat(
          dre.items.lucro_liquido.items[3].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          dre.items.lucro_liquido.items[3].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Lucro Líquido',
      type: 'highlight total',
      porcent: index =>
        Math.round(
          (dre.items.lucro_liquido.total[index] * 100) /
            dre.items.receita_liquida.total[index]
        ),
      value: index =>
        parseFloat(dre.items.lucro_liquido.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table
      className='table table-sm table-striped col-6 table-root'
      id='tableDREPDF'
    >
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>DRE</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                  <div className='col-porcent-item'>
                    <FaPercentage />
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {dreContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                      <div className='col-porcent-item'>
                        <span>{item.porcent(index)}</span>
                        <span>
                          <FaPercentage />
                        </span>
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  dre: state.dre
})

export default connect(mapStateToProps)(TableDRE)

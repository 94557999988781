export function updateSimpleData (key, value) {
  return {
    type: 'DRE_UPDATE_SIMPLE_DATA',
    key,
    value
  }
}

export function updateTableExcel (el, keyNameItems, keyItem, keyData, value) {
  return {
    type: 'DRE_UPDATE_TABLE_EXCEL',
    el,
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function sumTotalEl (el, keyNameItems, keyData, value) {
  return {
    type: 'DRE_SUM_VALUES_FROM_ELEMENT',
    el,
    key_name_items: keyNameItems,
    key_data: keyData,
    value
  }
}

export function minusTotalEl (el, keyNameItems, keyData, value) {
  return {
    type: 'DRE_MINUS_VALUES_FROM_ELEMENT',
    el,
    key_name_items: keyNameItems,
    key_data: keyData,
    value
  }
}

export function superSum (keyNameItems, keyItem, keyData, value) {
  return {
    type: 'DRE_SUM_ALL_VALUES_BY_DATE',
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function porcentage (keyData) {
  return {
    type: 'DRE_PORCENTAGE_ALL_ELEMENTS',
    key_data: keyData
  }
}

export function updateProgress (keyName) {
  return {
    type: 'DRE_UPDATE_PROGRESS',
    keyName
  }
}

export function sumSubTotal (datas) {
  return {
    type: 'SUM_SUB_TOTAL_WITHOUT_VALUE',
    datas
  }
}

export function receitaLiquidaSubtract (keyData) {
  return {
    type: 'RECEITA_LIQUIDA_SUBTRACT',
    key_data: keyData
  }
}

export function lucrobruto (keyData) {
  return {
    type: 'LUCROBRUTO',
    key_data: keyData
  }
}

export function lucrooperacional (keyData) {
  return {
    type: 'LUCROOPERACIONAL',
    key_data: keyData
  }
}

export function lucrooperacionalliquido (keyData) {
  return {
    type: 'LUCROOPERACIONALLIQUIDO',
    key_data: keyData
  }
}

export function lucroliquido (keyData) {
  return {
    type: 'LUCROLIQUIDO',
    key_data: keyData
  }
}

export function updateDate (value) {
  return {
    type: 'UPDATE_DATE',
    value
  }
}

export function supertotal (keyData) {
  return {
    type: 'SUPER_TOTAL',
    key_data: keyData
  }
}

export function bootstrap (data, totalDataEl) {
  return {
    type: 'BOOTSTRAP_DRE',
    data,
    total_data_el: totalDataEl
  }
}

export function removeDataDRE (value, totalDates) {
  return {
    type: 'REMOVE_DATA_DRE',
    value,
    total_dates: totalDates
  }
}

export function addDataDRE () {
  return {
    type: 'ADD_NEW_DATE_DRE'
  }
}

import React from 'react'
import { connect } from 'react-redux'

import { FaPercentage } from 'react-icons/fa'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TablePassivo = props => {
  const { passivo, dados } = props

  const porcentFromTotal = (value, index) => {
    const passivoTotal = passivo.total[index]
    if (passivoTotal === 0) {
      return 0
    }
    const total = (value * 100) / passivoTotal
    return Math.round(parseFloat(total).toFixed(2))
  }

  const PassivoContent = [
    {
      name: 'Circulante',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(
          passivo.items.passivo_circulante.total &&
            passivo.items.passivo_circulante.total[index],
          index
        ),
      value: index =>
        parseFloat(
          passivo.items.passivo_circulante.total &&
            passivo.items.passivo_circulante.total[index]
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Fornecedores',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.total_bancos.items &&
            passivo.items.total_bancos.items[0].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.total_bancos.items &&
            passivo.items.total_bancos.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Bancos CP',
      type: 'normal',
      porcent: index => {
        if (passivo.items.total_bancos.items[5].datas[index].percent) {
          return parseFloat(
            parseFloat(
              passivo.items.total_bancos.items[5].datas[index].percent.replace(
                '%',
                ''
              )
            ) +
              parseFloat(
                passivo.items.total_bancos.items[6].datas[
                  index
                ].percent.replace('%', '')
              ) +
              parseFloat(
                passivo.items.total_bancos.items[7].datas[
                  index
                ].percent.replace('%', '')
              ) +
              parseFloat(
                passivo.items.total_bancos.items[8].datas[
                  index
                ].percent.replace('%', '')
              )
          ).toFixed(0)
        }
      },
      value: index => {
        return parseFloat(
          passivo.items.total_bancos.items[5].datas[index].value +
            passivo.items.total_bancos.items[6].datas[index].value +
            passivo.items.total_bancos.items[7].datas[index].value +
            passivo.items.total_bancos.items[8].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },

    {
      name: 'Provisão IR/CS/Sociais/Trb.',
      type: 'normal',
      porcent: index => {
        if (passivo.items.total_bancos.items[2].datas[index].percent) {
          return parseFloat(
            parseFloat(
              passivo.items.total_bancos.items[2].datas[index].percent.replace(
                '%',
                ''
              )
            ) +
              parseFloat(
                passivo.items.total_bancos.items[3].datas[
                  index
                ].percent.replace('%', '')
              )
          ).toFixed(0)
        }
      },
      value: index => {
        return parseFloat(
          passivo.items.total_bancos.items[2].datas[index].value +
            passivo.items.total_bancos.items[3].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },

    {
      name: 'Adiantamento de Clientes',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.passivo_circulante.items[0].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.passivo_circulante.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Partes Relacionadas',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.total_bancos.items[1].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.total_bancos.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Parcela Corrente de L.P.',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.total_bancos.items[10].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.total_bancos.items[10].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Exígivel a Longo Prazo',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(
          passivo.items.exigivel_longo_prazo.total[index],
          index
        ),
      value: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.total[index]
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Bancos Longo Prazo',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[0].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Tributos Parcelados',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[2].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Partes Relacionadas',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[1].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.exigivel_longo_prazo.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Patrimônio Líquido',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(passivo.items.patrimonio_liquido.total[index], index),
      value: index =>
        parseFloat(
          passivo.items.patrimonio_liquido.total[index]
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Capital Social',
      type: 'normal',
      porcent: index =>
        parseFloat(
          passivo.items.patrimonio_liquido.items[1].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          passivo.items.patrimonio_liquido.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Reservas de Capital / Lucros',
      type: 'normal',
      porcent: index => {
        if (passivo.items.patrimonio_liquido.items[3].datas[index].percent) {
          return Math.round(
            parseFloat(
              parseFloat(
                passivo.items.patrimonio_liquido.items[3].datas[
                  index
                ].percent.replace('%', '')
              ) +
                parseFloat(
                  passivo.items.patrimonio_liquido.items[4].datas[
                    index
                  ].percent.replace('%', '')
                ) +
                parseFloat(
                  passivo.items.patrimonio_liquido.items[5].datas[
                    index
                  ].percent.replace('%', '')
                )
            ).toFixed(0)
          )
        }
      },
      value: index => {
        return parseFloat(
          passivo.items.patrimonio_liquido.items[3].datas[index].value +
            passivo.items.patrimonio_liquido.items[4].datas[index].value +
            passivo.items.patrimonio_liquido.items[5].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },

    {
      name: 'Passivo Total',
      type: 'highlight total',
      porcent: index => porcentFromTotal(passivo.total[index], index),
      value: index =>
        parseFloat(passivo.total[index]).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table
      className='table table-sm table-striped col-6 table-root'
      id='tablePassivoPDF'
    >
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>Passivo</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                  <div className='col-porcent-item'>
                    <FaPercentage />
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {PassivoContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                      <div className='col-porcent-item'>
                        <span>{item.porcent(index)}</span>
                        <span>
                          <FaPercentage />
                        </span>
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  passivo: state.passivo
})

export default connect(mapStateToProps)(TablePassivo)

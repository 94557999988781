import { combineReducers } from 'redux'

import sidebar from './sidebar'
import dados from './dados'
import ativo from './ativo'
import passivo from './passivo'
import mutacao from './mutacao'
import fundos from './fundos'
import parceiros from './parceiros'
import dre from './dre'
import highlights from './highlights'
import rating from './rating'

export default combineReducers({
  sidebar,
  dados,
  ativo,
  passivo,
  mutacao,
  fundos,
  parceiros,
  dre,
  rating,
  highlights
})

import React, { useEffect } from 'react'

const ChangeLog = () => {
  useEffect(() => {}, [])

  return (
    <div style={{ height: '100%', color: 'white', fontSize: '10px' }}>
      <h5>Boa Nota Spread</h5>
      <h4>ChangeLog</h4>
      <h6>lastest version: 1.0.14</h6>
      <ul>
        <li>
          <div>version 1.0.1 - 24/04/2020</div>
          <ul>
            <li>[*] valores monetários aceitam números negativos</li>
            <li>[*] adicionado campo AFAC no patrimônio líquido</li>
            <li>[*] corrigido a formula das despesas financeiras</li>
            <li>
              [*] aumentado o campo para os valores monetários nos highlights
            </li>
            <li>[*] aumentado os campos financeiros de todas as páginas</li>
            <li>
              [*] removido a opção de digitar centavos nos campos financeiros
            </li>
            <li>[*] adicionado despesas tributárias no DRE</li>
          </ul>
        </li>

        <li>
          <div>version 1.0.2 - 28/04/2020</div>
          <ul>
            <li>[*] correção da soma do passivo</li>
          </ul>
        </li>

        <li>
          <div>version 1.0.3 - 30/04/2020</div>
          <ul>
            <li>
              [*] correção da soma do ativo - excluido o imobilizado da soma
            </li>
            <li>
              [*] correção da soma do passivo - seguindo o exato fluxo da
              planilha
            </li>
          </ul>
        </li>

        <li>
          <div>version 1.0.4 - 01/05/2020</div>
          <ul>
            <li>[*] correção do DRE</li>
            <li>[*] super total corrigido no passivo</li>
          </ul>
        </li>
        <li>
          <div>version 1.0.5 - 06/05/2020</div>
          <ul>
            <li>
              [*] ativo Outros Ativos não Operacionais estava duplicando o valor
              de ativos não operacionais
            </li>
          </ul>
        </li>
        <li>
          <div>version 1.0.6 - 06/05/2020</div>
          <ul>
            <li>
              [*] Geração de Caixa: Exibe apenas 3 datas, esconde se houver mais
            </li>
          </ul>
        </li>
        <li>
          <div>version 1.0.7 - 06/05/2020</div>
          <ul>
            <li>[*] Data em formatacao brasileiro dd/mm/yyyy</li>
            <li>[*] Corrigido porcentagem nos highlights</li>
          </ul>
        </li>
        <li>
          <div>version 1.0.8 - 07/05/2020</div>
          <ul>
            <li>[*] Corrigido bug para adicionar nova data</li>
            <li>[*] Corrigido bug para remover data</li>
            <li>[*] Datas travadas após salvar no servidor</li>
          </ul>
        </li>
        <li>
          <div>version 1.0.9 - 08/05/2020</div>
          <ul>
            <li>[*] Exibindo o botão para adicionar datas</li>
            <li>
              [*] Regra de proteção de datas se aplica ao carregar a pagina com
              dados salvos
            </li>
            <li>[*] Corrigido bug da pagina Parceiros</li>
            <li>[*] CNPJ com mascara validada pela logica do CNPJ</li>
            <li>[*] Corrigido bug ao salvar apenas nome e cnpj</li>
          </ul>
        </li>
        <li>
          <div>version 1.0.10 - 11/05/2020</div>
          <ul>
            <li>
              [*] conteudo carregado sem cnpj na url (pagina dados exceção)
            </li>
          </ul>
        </li>
        <li>
          <div>version 1.0.14 - 11/05/2020</div>
          <ul>
            <li>
              [*] adicionado Diferença de Patrimônio Líquido na Mutação e Ativo
              - Passivo (check) no Passivo
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default ChangeLog

import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { connect } from 'react-redux'

const ChartFundo = props => {
  const { fundos } = props

  const data = [
    {
      tudo: 'Total',
      Fundos: fundos.total_fundos,
      Bancos: fundos.total_bancos
    }
  ]

  return (
    <ResponsiveBar
      data={data}
      layout='horizontal'
      keys={['Fundos', 'Bancos']}
      indexBy='tudo'
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      padding={0}
      colors={{ scheme: 'nivo' }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      height={30}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'TOTAL',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['brighter', 100]] }}
      animate
      motionStiffness={90}
      motionDamping={15}
      style={{ width: '100%' }}
    />
  )
}

const mapStateToProps = state => ({
  fundos: state.fundos
})

export default connect(mapStateToProps)(ChartFundo)

import axios from 'axios'

import { API_BASE_URL } from '../config/env'
import { factoryDate, getIdToken, toCamelCase } from '../utils'

export const listSpreadsDates = async ({ documents }) => {
  const token = await getIdToken()
  const MAX_RETRIES = 10
  let pending = true
  let retries = 0
  let rawData = []
  do {
    try {
      const result = await Promise.all(
        documents.map(document =>
          axios
            .post(
              `${API_BASE_URL}/lista_datas`,
              {
                id: document.replace(/\D/g, ''),
                is_group: false
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            )
            .then(response => ({
              document,
              dates: response.data.data.map(date => factoryDate(date))
            }))
        )
      )
      rawData = result.flat()
      pending = false
    } catch (err) {
      console.error(err)
      if (retries > MAX_RETRIES) {
        throw new Error('Max retries reached')
      }

      if (
        err.response.status === 404 &&
        err.response.data.message === 'Not found'
      ) {
        pending = false
        continue
      }

      await new Promise(resolve => setTimeout(resolve, 1000))
      retries++
    }
  } while (pending)

  const mappedData = toCamelCase(rawData)
  return mappedData
}

export const reorderDates = async ({ document, isGroup }) => {
  const token = await getIdToken()

  const { data } = await axios.post(
    `${API_BASE_URL}/reorder-dates`,
    {
      cnpj: document,
      is_group: !!isGroup
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return toCamelCase(data)
}

export const consolidateGroup = async ({
  targetDocument,
  empresasConsolidadas
}) => {
  const token = await getIdToken()
  const cnpjGroup = empresasConsolidadas
    .filter(item => item.valid)
    .map(item => item.value)

  const documents = Array.from(
    new Set(cnpjGroup.map(document => document.replace(/\D/g, '')))
  )

  const { data } = await axios.post(
    `${API_BASE_URL}/consolidacao-grupo`,
    {
      cnpj_controladora: targetDocument,
      cnpjs_grupo: documents
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return toCamelCase(data)
}

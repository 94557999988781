import React from 'react'
import { connect } from 'react-redux'

import 'react-step-progress-bar/styles.css'

const Footer = () => {
  return <div />
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  mutacao: state.mutacao
})

export default connect(mapStateToProps)(Footer)

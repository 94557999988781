import React from 'react'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

import LoginIcon from '../../assets/img/login.svg'

export default function Login () {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'primary.main',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 12,
          paddingRight: 12,
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '75%',
          maxWidth: '600px'
        }}
      >
        <img src={LoginIcon} height={200} alt='Login' />
        <h5>É bom ver você aqui novamente</h5>
        <span>
          Entre agora e veja todos os insights que proporcionamos para você!
        </span>
        <button
          className='btn btn-primary'
          type='button'
          style={{ marginTop: 12, marginBottom: 12, maxWidth: '500px' }}
          onClick={() =>
            Auth.federatedSignIn().catch(err => {
              console.error(err)
              toast.error(err.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: 'login-error'
              })
            })
          }
        >
          Entrar
        </button>
      </div>
    </div>
  )
}

import JSON_TABLE from './../../assets/table-fundos.json'

const INITIAL_STATE = JSON_TABLE

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'BOOTSTRAP_FUNDOS') {
    if (action.data) {
      return action.data.data
    }
  }

  if (action.type === 'FUNDOS_UPDATE_SIMPLE_DATA') {
    const newValue = action.value.replace('.', '').replace('.', '')

    switch (action.typeFundo) {
      case 'fundos':
        return {
          ...state,
          fundos: state.fundos.map((fundo, key) =>
            key === action.key
              ? {
                ...fundo,
                total: (state.fundos[key].total = newValue)
              }
              : fundo
          )
        }

      case 'bancos':
        return {
          ...state,
          bancos: state.bancos.map((fundo, key) =>
            key === action.key
              ? {
                ...fundo,
                total: (state.bancos[key].total = newValue)
              }
              : fundo
          )
        }

      default:
        break
    }
  }

  if (action.type === 'FUNDOS_UPDATE_STRING') {
    switch (action.typeFundo) {
      case 'fundos':
        switch (action.typeString) {
          case 'instituicao':
            return {
              ...state,
              fundos: state.fundos.map((fundo, key) =>
                key === action.key
                  ? {
                    ...fundo,
                    instituicao: (state.fundos[key].instituicao = action.value)
                  }
                  : fundo
              )
            }
          case 'obs':
            return {
              ...state,
              fundos: state.fundos.map((fundo, key) =>
                key === action.key
                  ? {
                    ...fundo,
                    obs: (state.fundos[key].obs = action.value)
                  }
                  : fundo
              )
            }
          default:
            break
        }
        break

      case 'bancos':
        switch (action.typeString) {
          case 'instituicao':
            return {
              ...state,
              bancos: state.bancos.map((fundo, key) =>
                key === action.key
                  ? {
                    ...fundo,
                    instituicao: (state.bancos[key].instituicao = action.value)
                  }
                  : fundo
              )
            }
          case 'obs':
            return {
              ...state,
              bancos: state.bancos.map((fundo, key) =>
                key === action.key
                  ? {
                    ...fundo,
                    obs: (state.bancos[key].obs = action.value)
                  }
                  : fundo
              )
            }
          default:
            break
        }
        break

      default:
        break
    }
  }

  if (action.type === 'SUM_TOTAL') {
    let totalTemp = 0
    let totalBancosTemp = 0
    let totalFundosTemp = 0

    // SUM FUNDOS
    state.fundos.map(fundo => {
      if (typeof fundo.total === 'string') {
        fundo.total = parseFloat(fundo.total)
      }
      totalFundosTemp += fundo.total
      return null
    })

    // SUM BANCOS
    state.bancos.map(banco => {
      if (typeof banco.total === 'string') {
        banco.total = parseFloat(banco.total)
      }
      totalBancosTemp += banco.total
      return null
    })

    totalTemp = totalFundosTemp + totalBancosTemp

    return {
      ...state,
      total: totalTemp,
      total_fundos: totalFundosTemp,
      total_bancos: totalBancosTemp
    }
  }

  if (action.type === 'ADD_ITEM') {
    const tempItem = {
      instituicao: action.name,
      total: 0,
      obs: ''
    }

    switch (action.typeItem) {
      case 'fundos':
        return {
          ...state,
          fundos: [...state.fundos, tempItem]
        }

      case 'bancos':
        return {
          ...state,
          bancos: [...state.bancos, tempItem]
        }

      default:
        break
    }
  }

  if (action.type === 'REMOVE_ITEM') {
    switch (action.typeItem) {
      case 'fundos':
        return {
          ...state,
          fundos: state.fundos.filter((fundo, key) => key !== action.index)
        }
      case 'bancos':
        return {
          ...state,
          bancos: state.bancos.filter((banco, key) => key !== action.index)
        }
      default:
        break
    }
  }

  return state
}

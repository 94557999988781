import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableFluxoCaixa = props => {
  const { dados, highlights } = props
  const { highlights_corporate: highlightsCorporate } = highlights
  const { fluxo_caixa: fluxoCaixa, indicadores } = highlightsCorporate

  const dadosTemp = JSON.parse(JSON.stringify(dados))

  useEffect(() => {
    if (dadosTemp.datas.length > 3) {
      dadosTemp.datas.pop()
    }
  }, [])

  const FluxoContent = [
    {
      name: 'EBITDA',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.ebitda[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Serviço da Dívida',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.servico_da_divida[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Geração Operacional de Caixa',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.geracao_operacional_caixa[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Necessidade Adicional de Giro',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.necessidade_adicional_giro[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Geração Operacional Líquida de Caixa',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.geracao_operacional_liquida_caixa[index].toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },
    {
      name: 'Inversões em Ativo Fixo',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.inversoes_ativo_fixo[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Geração de Caixa após Inversões',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.geracao_caixa_apos_inversoes_fixas[index].toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },
    {
      name: 'Outros passivos de LP',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.outros_passivos_lp[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Dividendos e gratificações pagos',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.dividendos_gratificacoes_pagos[index].toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },
    {
      name: 'Imposto de Renda Pago',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.ir_pago[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Empréstimos e Partes Relacionadas',
      type: 'normal',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.emprestimos_a_partes_relacionadas_lp[index].toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },
    {
      name: 'Geração de Caixa no Período',
      type: 'highlight total',
      value: index =>
        fluxoCaixa &&
        fluxoCaixa.geracao_caixa_no_periodo[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    }
  ]

  const Indicadores = [
    {
      name: 'Receitas líquidas',
      type: 'normal',
      value: index =>
        indicadores &&
        indicadores.receita_liquida[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Δ%',
      type: 'normal',
      value: index => indicadores && '' + indicadores.delta_porcentagem[index]
    },
    {
      name: 'Endividamento Bancário Total',
      type: 'normal',
      value: index =>
        indicadores &&
        indicadores.endividamento_bancario_total[index].toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },
    {
      name: 'Δ% Endiv Bancário Total (AxA)',
      type: 'normal',
      value: index =>
        indicadores &&
        '' + indicadores.delta_endiv_bancario_total_anualizado[index]
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <div className='col-6 row'>
      <table
        className='table table-sm table-striped table-root col-12'
        style={{ maxWidth: '100%' }}
      >
        <thead className='thead-dark bg-primary'>
          <tr>
            <th scope='col'>Fluxo de Caixa</th>
            {
              // ADD COLUMN DATE
              dadosTemp.datas.map((date, index) => {
                return (
                  <th key={index} scope='col' className='col-data'>
                    <div className='col-date-item'>{getDate(date.data)}</div>
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {FluxoContent.map((item, index) => {
            return (
              <tr key={index} className={item.type + ' row-item'}>
                <th scope='row' className='table-name-item'>
                  {item.name}
                </th>
                {
                  // ADD COLUMN DATE
                  dadosTemp.datas.map((date, index) => {
                    return (
                      <th key={index} scope='col' className='col-data'>
                        <div
                          className={
                            (checkIfMinus(item.value(index))
                              ? 'minus-value'
                              : 'plus-value') + ' col-date-item'
                          }
                        >
                          {item.value(index)}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            )
          })}
        </tbody>
      </table>

      <table
        className='table table-sm table-striped table-root col-12'
        style={{ maxWidth: '100%', position: 'relative' }}
      >
        <thead className='thead-dark bg-primary'>
          <tr>
            <th scope='col'>Indicadores</th>
            {
              // ADD COLUMN DATE
              dadosTemp.datas.map((date, index) => {
                return (
                  <th key={index} scope='col' className='col-data'>
                    <div className='col-date-item'>{getDate(date.data)}</div>
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {Indicadores.map((item, index) => {
            return (
              <tr key={index} className={item.type + ' row-item'}>
                <th scope='row' className='table-name-item'>
                  {item.name}
                </th>
                {
                  // ADD COLUMN DATE
                  dadosTemp.datas.map((date, index) => {
                    return (
                      <th key={index} scope='col' className='col-data'>
                        <div
                          className={
                            (checkIfMinus(item.value(index))
                              ? 'minus-value'
                              : 'plus-value') + ' col-date-item'
                          }
                        >
                          {item.value(index)}
                        </div>
                      </th>
                    )
                  })
                }
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  highlights: state.highlights
})

export default connect(mapStateToProps)(TableFluxoCaixa)

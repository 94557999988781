const INITIAL_STATE = {
  activeMenu: 0,
  showChart: true,
  showSubtotal: true,
  dadosSalvos: false,
  isGroup: undefined
}

export default function sidebar (state = INITIAL_STATE, action) {
  if (action.type === 'SET_GROUP') {
    return { ...state, isGroup: action.data }
  }

  if (action.type === 'SIDEBAR_CLICK') {
    return { ...state, activeMenu: action.menuItem }
  }

  if (action.type === 'SHOW_CHART') {
    return {
      ...state,
      showChart: action.data
    }
  }

  if (action.type === 'SHOW_SUBTOTAL') {
    return {
      ...state,
      showSubtotal: action.data
    }
  }

  return state
}

export function clickSidebar (menuItem) {
  return {
    type: 'SIDEBAR_CLICK',
    menuItem
  }
}

export function setGroup (data) {
  return {
    type: 'SET_GROUP',
    data
  }
}

export function showChart (data) {
  return {
    type: 'SHOW_CHART',
    data
  }
}

export function showSubtotal (data) {
  return {
    type: 'SHOW_SUBTOTAL',
    data
  }
}

import React from 'react'
import { connect } from 'react-redux'

import { FaChartBar } from 'react-icons/fa'

import * as SidebarActions from './../../store/actions/sidebar'

import './chartToogle.css'

const ChartToogle = props => {
  const { sidebar, dispatch } = props

  const chartChange = () => {
    dispatch(SidebarActions.showChart(!sidebar.showChart))
  }

  return (
    <div className='chart-root'>
      <div
        className={
          sidebar.showChart ? 'chart-normal chart-btn' : 'chart-hide2 chart-btn'
        }
        onClick={() => chartChange()}
      >
        <FaChartBar size={20} className='chart-icon' />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
})

export default connect(mapStateToProps)(ChartToogle)

export function addItem (item) {
  return {
    type: 'ADD_ITEM',
    item
  }
}

export function changeItem (index, keyValue, value) {
  return {
    type: 'CHANGE_ITEM',
    index,
    keyValue,
    value
  }
}

export function removeItem (index) {
  return {
    type: 'REMOVE_ITEM',
    index
  }
}

export function bootstrap (data) {
  return {
    type: 'BOOTSTRAP_PARCEIROS',
    data
  }
}

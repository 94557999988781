import React from 'react'
import { connect } from 'react-redux'
import { format as formatFns } from 'date-fns'
import ptbr from 'date-fns/locale/pt'
import { ResponsiveLine } from '@nivo/line'

import './item-currency.css'

const ChartPie = props => {
  const { data, dados } = props

  const dates = (dados.datas || []).map(item =>
    formatFns(item.data, 'dd/MM/yyyy', { locale: ptbr })
  )
  const parsedDatas = (data.items || []).map(item => ({
    id: item.item,
    data: item.datas.map((value, index) => ({
      x: dates[index] || '',
      y: value.value || 0
    }))
  }))

  return (
    <div className='row'>
      <div className='col-auto' style={{ width: '100%', height: '400px' }}>
        <div className='data chart-name'>gráfico do período</div>
        <ResponsiveLine
          data={parsedDatas}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'período',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'valor',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          colors={{ scheme: 'nivo' }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel='y'
          pointLabelYOffset={-12}
          useMesh
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 200,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 250,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo
})

export default connect(mapStateToProps)(ChartPie)

export const checkIfMinus = checkValue => {
  if (checkValue) {
    if (checkValue.indexOf('-') >= 0) {
      return true
    }
  }

  // if (value) {

  // 	// const temp = value.split('R$ ');
  // 	const valueNew = parseFloat(value);
  // 	if (valueNew < 0) {
  // 		return true;
  // 	}
  // }

  return false
}

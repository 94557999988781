import React from 'react'
import { connect } from 'react-redux'

import { FaPercentage } from 'react-icons/fa'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableAtivo = props => {
  const { ativo, dados } = props

  const porcentFromTotal = (value, index) => {
    const ativoTotal = ativo.total[index]
    if (ativoTotal === 0) {
      return 0
    }
    const total = (value * 100) / ativoTotal
    return Math.round(parseFloat(total).toFixed(2))
  }

  const AtivoContent = [
    {
      name: 'Circulante 2',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(ativo.items.ativo_circulante.total[index], index),
      value: index =>
        ativo.items.ativo_circulante.total[index].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },
    {
      name: 'Caixa + Apl',
      type: 'normal',
      porcent: index => {
        return parseFloat(
          ativo.items.ativo_circulante.items[0].datas[index].percent +
            ativo.items.ativo_circulante.items[0].datas[index].percent
        ).toFixed(0)
      },
      value: index => {
        return parseFloat(
          ativo.items.ativo_circulante.items &&
            ativo.items.ativo_circulante.items[0].datas[index].value +
              ativo.items.ativo_circulante.items[1].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
    },
    {
      name: 'Contas a Receber',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[2].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Estoques',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[4].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[4].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Adto. Fornecedores',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[5].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[5].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Impostos a Recuperar',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[6].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[6].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Partes Relacionadas',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[7].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.ativo_circulante.items[7].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Realizável a Longo Prazo',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(
          ativo.items.realizavel_longo_prazo.total[index],
          index
        ),
      value: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.total[index]
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Partes Relacionadas',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[0].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[0].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Contas a Receber de Clientes',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[2].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[2].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Outras Contas',
      type: 'normal',
      porcent: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[3].datas[index].percent
        ).toFixed(0),
      value: index =>
        parseFloat(
          ativo.items.realizavel_longo_prazo.items[3].datas[index].value
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Permanente',
      type: 'highlight',
      porcent: index =>
        porcentFromTotal(ativo.items.ativo_permanente.total[index], index),
      value: index =>
        parseFloat(ativo.items.ativo_permanente.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Investimentos',
      type: 'normal',
      porcent: index =>
        porcentFromTotal(ativo.items.investimentos.total[index], index),
      value: index =>
        parseFloat(ativo.items.investimentos.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Imobilizados',
      type: 'normal',
      porcent: index =>
        porcentFromTotal(ativo.items.imobilizado.total[index], index),
      value: index =>
        parseFloat(ativo.items.imobilizado.total[index]).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Ativo Total',
      type: 'highlight total',
      porcent: index => porcentFromTotal(ativo.total[index], index),
      value: index =>
        parseFloat(ativo.total[index]).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table
      className='table table-sm table-striped col-6 table-root'
      id='tableAtivoPDF'
    >
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>Ativo</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                  <div className='col-porcent-item'>
                    <FaPercentage />
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {AtivoContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                      <div className='col-porcent-item'>
                        <span>{item.porcent(index)}</span>
                        <span>
                          <FaPercentage />
                        </span>
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo
})

export default connect(mapStateToProps)(TableAtivo)

import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import Column from './column'

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

export default function Dnd ({ state, onChange }) {
  const handleOnDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) {
      return
    }

    if (
      destination?.droppableId === source.droppableId &&
      destination?.index === source.index
    ) {
      return
    }

    const startColumn = state.columns[source.droppableId]
    const finishColumn = state.columns[destination.droppableId]

    if (startColumn === finishColumn) {
      const column = state.columns[source.droppableId]
      const newCategoriesIds = Array.from(column.categoryIds)
      newCategoriesIds.splice(source.index, 1)
      newCategoriesIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...column,
        categoryIds: newCategoriesIds
      }
      onChange(prev => ({
        ...prev,
        columns: {
          ...prev.columns,
          [newColumn.id]: newColumn
        }
      }))
    } else if (startColumn !== finishColumn) {
      const columns = move(
        startColumn.categoryIds,
        finishColumn.categoryIds,
        source,
        destination
      )
      onChange(prev => ({
        ...prev,
        columns: {
          ...prev.columns,
          [startColumn.id]: {
            ...startColumn,
            categoryIds: columns[startColumn.id]
          },
          [finishColumn.id]: {
            ...finishColumn,
            categoryIds: columns[finishColumn.id]
          }
        }
      }))
    }
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        className='d-flex flex-wrap justify-content-center'
        style={{ gap: '1rem' }}
      >
        {state.columnOrder.map(columnId => {
          const column = state.columns[columnId]
          const categories = column.categoryIds.map(
            categoryId => state.categories[categoryId]
          )

          return (
            <Column key={column.id} column={column} categories={categories} />
          )
        })}
      </div>
    </DragDropContext>
  )
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import * as DadosActions from './../store/actions/dados_datas'
import * as AtivoActions from './../store/actions/ativo'
import * as PassivoActions from './../store/actions/passivo'
import * as MutacaoActions from './../store/actions/mutacao'
import * as DREActions from './../store/actions/dre'
import * as FundosActions from './../store/actions/fundos'
import * as ParceirosActions from './../store/actions/parceiros'
import * as HighlightsActions from './../store/actions/highlights'
import { bootstrapRating } from './../store/reducers/rating'

import { loadAllDataFromAPI } from './index'

const UpdateRedux = props => {
  const { dispatch } = props

  useEffect(() => {
    const cnpj = window.location.href.split('/')[4]

    if (cnpj) {
      Swal.fire({
        title: 'Carregando informações'
      })
      Swal.showLoading()
      loadData({ dispatch, cnpj, isGroup: false, withMessage: true })
    }
  }, [dispatch])

  return <div />
}

export const loadData = async ({ cnpj, dispatch, isGroup, withMessage }) => {
  try {
    const res = await loadAllDataFromAPI(cnpj, isGroup)

    const totalDatas = res.dados.datas.length

    if (res.dados.data_fundacao === '') {
      res.dados.data_fundacao = new Date()
    }

    if (res.dados.datas.length > 0) {
      res.dados.datas.map(data => {
        data.enabled = true
        return data
      })
    }

    const mergedDados = {
      ...res.dados,
      groupExists: res.groupExists,
      empresas_consolidadas: isGroup
        ? res.dados.empresas_consolidadas
        : res.empresasConsolidadasGrupo
    }

    await dispatch(DadosActions.bootstrap(mergedDados))
    await dispatch(AtivoActions.bootstrap(res.ativos, totalDatas))
    await dispatch(PassivoActions.bootstrap(res.passivos, totalDatas))
    await dispatch(MutacaoActions.bootstrap(res.mutacao, totalDatas))
    await dispatch(DREActions.bootstrap(res.dre, totalDatas))
    await dispatch(FundosActions.bootstrap(res.fundos))
    await dispatch(ParceirosActions.bootstrap(res.parceiros))
    dispatch(bootstrapRating(res.ratingParameters ?? {}))

    if (res.highlights && res.highlights_corporate && res.geracao_caixa_livre) {
      const high = {
        highlights: res.highlights,
        highlights_corporate: res.highlights_corporate,
        geracao_caixa_livre: res.geracao_caixa_livre
      }

      await dispatch(HighlightsActions.bootstrap(high))
    }

    if (Swal.isLoading()) {
      Swal.close()
    }
  } catch (err) {
    console.error(err)
    const errorMessage = err.message
    await dispatch(DadosActions.bootstrap(null, 0))
    await dispatch(AtivoActions.bootstrap(null, 0))

    if (!withMessage) {
      throw err
    }

    if (errorMessage.includes('Document not found')) {
      Swal.fire(
        'Documento não encontrado',
        'O documento não foi encontrado. Por favor, verifique o CNPJ e tente novamente. Se o problema persistir, entre em contato com o suporte do Boa Nota.',
        'error'
      )
    } else if (errorMessage.includes('User group not authorized')) {
      Swal.fire(
        'Acesso negado',
        'Você não tem permissão para acessar o spread. Por favor, entre em contato com o suporte do Boa Nota para liberar o acesso.',
        'error'
      )
    } else {
      Swal.fire(
        'ERRO',
        'Desculpe ocorreu um erro no servidor, contate o suporte do Boa Nota',
        'error'
      )
    }
  }
}

const mapStateToProps = state => ({
  ativo: state.ativo,
  dados: state.dados,
  sidebar: state.sidebar,
  passivo: state.passivo,
  mutacao: state.mutacao,
  fundos: state.fundos,
  highlights: state.highlights
})

export default connect(mapStateToProps)(UpdateRedux)

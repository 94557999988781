import JSON_TABLE from './../../assets/table-passivo.json'

const progress = { ...JSON_TABLE }
Object.keys(progress).forEach(function (item) {
  progress[item] = false
})

delete progress.total

const INITIAL_STATE = {
  items: JSON_TABLE,
  progress,
  progress_show_tooltip: false
}

// FILL WITH {value: 0, percent: 0} if item has a different elements in array
const fillIfsEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill({ value: 0, percent: 0 })
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = { value: 0, percent: 0 }
      }
    }
    return originValue
  }
  return originValue
}

// FILL WITH 0 i item has different elements in array
const totalIfEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill(0)
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = 0
      }
    }
    return originValue
  }
  return originValue
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'BOOTSTRAP_PASSIVO') {
    if (action.data) {
      const totalBancos = [
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.fornecedores,
            action.total_data_el
          ),
          id_back: 'fornecedores',
          item: 'Fornecedores',
          type: '+',
          id: 0
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.partes_relacionadas_total_bancos,
            action.total_data_el
          ),
          id_back: 'partes_relacionadas_total_bancos',
          item: 'Partes Relacionadas',
          type: '+',
          id: 1
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.provisao_ir,
            action.total_data_el
          ),
          id_back: 'provisao_ir',
          item: 'Provisão IR/Contribuição Social',
          type: '+',
          id: 2
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.provisoes_sociais_tributarias,
            action.total_data_el
          ),
          id_back: 'provisoes_sociais_tributarias',
          item: 'Provisões Sociais e Tributárias',
          type: '+',
          id: 3
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.dividendos_part_adm_empr,
            action.total_data_el
          ),
          id_back: 'dividendos_part_adm_empr',
          item: 'Dividendos/Part. Adm./Empr.',
          type: '+',
          id: 4
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.duplicatas_descontadas,
            action.total_data_el
          ),
          id_back: 'duplicatas_descontadas',
          item: 'Duplicatas Descontadas',
          type: '+',
          id: 5
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.emprestimos_cp_moeda_nacional,
            action.total_data_el
          ),
          id_back: 'emprestimos_cp_moeda_nacional',
          item: 'Emp. de Curto Prazo (nacional)',
          type: '+',
          id: 6
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.emprestimos_cp_moeda_estrangeira,
            action.total_data_el
          ),
          id_back: 'emprestimos_cp_moeda_estrangeira',
          item: 'Emp. de Curto Prazo (estrang.)',
          type: '+',
          id: 7
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.acc_ace,
            action.total_data_el
          ),
          id_back: 'acc_ace',
          item: 'ACC/ACE',
          type: '+',
          id: 8
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.importacao,
            action.total_data_el
          ),
          id_back: 'importacao',
          item: 'Importação',
          type: '+',
          id: 9
        },
        {
          datas: fillIfsEmpty(
            action.data.total_bancos.parcela_corrente_emprestimo_lp,
            action.total_data_el
          ),
          id_back: 'parcela_corrente_emprestimo_lp',
          item: 'Parcela Corrente Empréstimo de LP.',
          type: '+',
          id: 10
        }
      ]

      const passivoCirculante = [
        {
          datas: fillIfsEmpty(
            action.data.passivo_circulante.adiantamento_de_clientes,
            action.total_data_el
          ),
          id_back: 'adiantamento_de_clientes',
          item: 'Adiantamento de Clientes',
          type: '+',
          id: 11
        },
        {
          datas: fillIfsEmpty(
            action.data.passivo_circulante.outros_passivos_operacionais,
            action.total_data_el
          ),
          id_back: 'outros_passivos_operacionais',
          item: 'Outros Passivos Operacionais',
          type: '+',
          id: 12
        },
        {
          datas: fillIfsEmpty(
            action.data.passivo_circulante.outros_passivos_nao_operacionais,
            action.total_data_el
          ),
          id_back: 'outros_passivos_nao_operacionais',
          item: 'Outros Passivos não Operacionais',
          type: '+',
          id: 13
        },
        {
          datas: fillIfsEmpty(
            action.data.passivo_circulante.outras_contas,
            action.total_data_el
          ),
          id_back: 'outras_contas',
          item: 'Outras Contas',
          type: '+',
          id: 14
        }
      ]

      const exigivelLongoPrazo = [
        {
          datas: fillIfsEmpty(
            action.data.exigivel_longo_prazo
              .emprestimo_financiamento_debentures,
            action.total_data_el
          ),
          id_back: 'emprestimo_financiamento_debentures',
          item: 'Empréstimo/Financiamento/Debentures',
          type: '+',
          id: 15
        },
        {
          datas: fillIfsEmpty(
            action.data.exigivel_longo_prazo.partes_relacionadas_exigivel_lp,
            action.total_data_el
          ),
          id_back: 'partes_relacionadas_exigivel_lp',
          item: 'Partes Relacionadas',
          type: '+',
          id: 16
        },
        {
          datas: fillIfsEmpty(
            action.data.exigivel_longo_prazo.tributos_parcelados,
            action.total_data_el
          ),
          id_back: 'tributos_parcelados',
          item: 'Tributos Parcelados',
          type: '+',
          id: 17
        },
        {
          datas: fillIfsEmpty(
            action.data.exigivel_longo_prazo.ir_diferido,
            action.total_data_el
          ),
          id_back: 'ir_diferido',
          item: 'Imposto de Renda Diferido',
          type: '+',
          id: 18
        },
        {
          datas: fillIfsEmpty(
            action.data.exigivel_longo_prazo.outras_contas_lp,
            action.total_data_el
          ),
          id_back: 'outras_contas_lp',
          item: 'Outras Contas de Longo Prazo',
          type: '+',
          id: 19
        }
      ]

      const resultadoExercicioFuturo = [
        {
          datas: fillIfsEmpty(
            action.data.resultado_exercicio_futuro.resultado_exercicio_futuro,
            action.total_data_el
          ),
          id_back: 'resultado_exercicio_futuro',
          item: 'Resultado do exercício futuro',
          type: '+',
          id: 20
        }
      ]

      const patrimonioLiquido = [
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.interesses_minoritarios,
            action.total_data_el
          ),
          id_back: 'interesses_minoritarios',
          item: 'Intereses Minoritários',
          type: '+',
          id: 21
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.capital_integralizado,
            action.total_data_el
          ),
          id_back: 'capital_integralizado',
          item: 'Capital Integralizado',
          type: '+',
          id: 22
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.AFAC,
            action.total_data_el
          ),
          id_back: 'AFAC',
          item: 'AFAC',
          type: '+',
          id: 27
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.reserva_capital,
            action.total_data_el
          ),
          id_back: 'reserva_capital',
          item: 'Reserva de Capital',
          type: '+',
          id: 23
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.reserva_lucros,
            action.total_data_el
          ),
          id_back: 'reserva_lucros',
          item: 'Reserva de Lucros',
          type: '+',
          id: 24
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.lucro_prejuizo_acumulado,
            action.total_data_el
          ),
          id_back: 'lucro_prejuizo_acumulado',
          item: 'Lucro/Prej. Acum.',
          type: '+',
          id: 25
        },
        {
          datas: fillIfsEmpty(
            action.data.patrimonio_liquido.reserva_reavaliacao,
            action.total_data_el
          ),
          id_back: 'reserva_reavaliacao',
          item: 'Reserva de Reavaliação',
          type: '+',
          id: 26
        }
      ]

      const prevState = {
        ...state,
        items: {
          ...state.items,
          total_bancos: {
            ...state.items.total_bancos,
            items: totalBancos,
            total: totalIfEmpty(
              action.data.total_bancos.total,
              action.total_data_el
            )
          },
          passivo_circulante: {
            ...state.items.passivo_circulante,
            items: passivoCirculante,
            total: totalIfEmpty(
              action.data.passivo_circulante.total,
              action.total_data_el
            )
          },
          exigivel_longo_prazo: {
            ...state.items.exigivel_longo_prazo,
            items: exigivelLongoPrazo,
            total: totalIfEmpty(
              action.data.exigivel_longo_prazo.total,
              action.total_data_el
            )
          },
          resultado_exercicio_futuro: {
            ...state.items.resultado_exercicio_futuro,
            items: resultadoExercicioFuturo,
            total: totalIfEmpty(
              action.data.resultado_exercicio_futuro.total,
              action.total_data_el
            )
          },
          patrimonio_liquido: {
            ...state.items.patrimonio_liquido,
            items: patrimonioLiquido,
            total: totalIfEmpty(
              action.data.patrimonio_liquido.total,
              action.total_data_el
            )
          },

          exigivel_total: {
            total: totalIfEmpty(
              action.data.exigivel_total.total,
              action.total_data_el
            ),
            items: []
          },

          ativo_passivo_check: {
            total: totalIfEmpty(
              action.data.ativo_passivo_check.total,
              action.total_data_el
            ),
            items: []
          },

          total: totalIfEmpty(action.data.total, action.total_data_el)
        },
        total: totalIfEmpty(action.data.total, action.total_data_el)
      }

      return prevState
    } else {
      const prevState = { ...state }
      prevState.items.total_bancos.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.total_bancos.total = Array(action.total_data_el).fill(0)

      prevState.items.passivo_circulante.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.passivo_circulante.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.exigivel_longo_prazo.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.exigivel_longo_prazo.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.resultado_exercicio_futuro.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.resultado_exercicio_futuro.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.patrimonio_liquido.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.patrimonio_liquido.total = Array(
        action.total_data_el
      ).fill(0)
      prevState.items.exigivel_total.total = Array(action.total_data_el).fill(0)
      prevState.items.ativo_passivo_check.total = Array(
        action.total_data_el
      ).fill(0)
      prevState.items.total = Array(action.total_data_el).fill(0)
      prevState.total = Array(action.total_data_el).fill(0)

      return prevState
    }
  }

  if (action.type === 'PASSIVO_UPDATE_SIMPLE_DATA') {
    return {
      ...state,
      [action.key]: action.value,
      progress: {
        ...state.progress,
        [action.key]: true
      }
    }
  }

  if (action.type === 'PASSIVO_UPDATE_TABLE_EXCEL') {
    return {
      ...state,
      progress: {
        ...state.progress,
        [action.key_name_items]: true
      },
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      value: action.value
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'PASSIVO_SUM_VALUES_FROM_ELEMENT') {
    let total = 0

    state.items[action.key_name_items].items.map(item => {
      const value = item.datas[action.key_data].value
      total += value
      return null
    })

    const value = parseFloat(action.value)
    const percent = total !== 0 ? (value * 100) / total : 0

    return {
      ...state,
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          total: state.items[action.key_name_items].total.map(
            (itemTotal, keyTotal) =>
              keyTotal === action.key_data ? total : itemTotal
          ),
          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      percent
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'PASSIVO_SUM_ALL_VALUES_BY_DATE') {
    const passivoCirculante =
      state.items.passivo_circulante.total[action.key_data]
    const exigivelLongoPrazo =
      state.items.exigivel_longo_prazo.total[action.key_data]
    const resultadoExercicioFuturo =
      state.items.resultado_exercicio_futuro.total[action.key_data]
    const interessesMinoritatios =
      state.items.patrimonio_liquido.items[0].datas[action.key_data].value
    const capitalIntegralizado =
      state.items.patrimonio_liquido.items[1].datas[action.key_data].value
    const afac =
      state.items.patrimonio_liquido.items[2].datas[action.key_data].value
    const reservaCapital =
      state.items.patrimonio_liquido.items[3].datas[action.key_data].value
    const reservaLucros =
      state.items.patrimonio_liquido.items[4].datas[action.key_data].value
    const lucrosPrejuizoAcumulado =
      state.items.patrimonio_liquido.items[5].datas[action.key_data].value
    const reservaReavaliacao =
      state.items.patrimonio_liquido.items[6].datas[action.key_data].value

    const totalFinal =
      passivoCirculante +
      exigivelLongoPrazo +
      resultadoExercicioFuturo +
      interessesMinoritatios +
      capitalIntegralizado +
      afac +
      reservaCapital +
      reservaLucros +
      lucrosPrejuizoAcumulado +
      reservaReavaliacao

    // let object2Array = Object.entries(state.items);
    // object2Array.pop(); //last element is total obj -> is necessary remove them
    // let total = 0;

    // object2Array.map((item) => {
    // 	total += item[1].total[action.key_data];
    // 	return null;
    // });

    return {
      ...state,
      total: state.total.map((value, key) =>
        key === action.key_data ? totalFinal : value
      ),
      items: {
        ...state.items,
        total: state.total.map((value, key) =>
          key === action.key_data ? totalFinal : value
        )
      }
    }
  }

  // this action is for recalculate all porcentage for this respective date column (by: action.keydata)
  if (action.type === 'PASSIVO_PORCENTAGE_ALL_ELEMENTS') {
    const total = state.total[action.key_data]
    const porcentageCalc = value => {
      return total === 0 ? 0 : ((value * 100) / total).toFixed(0)
    }
    const newStateItems = Object.keys(state.items)
    newStateItems.pop() // last key is super total

    newStateItems.forEach((key, item) => {
      state.items[key].items.map(item => {
        return item.datas.map((data, key) => {
          if (key === action.key_data) {
            data.percent = porcentageCalc(data.value) + '%'
          } else {
            return data
          }
          return null
        })
      })
      return null
    })

    return {
      ...state,
      items: state.items
    }
  }

  if (action.type === 'PASSIVO_UPDATE_PROGRESS') {
    return {
      ...state,
      progress: {
        ...progress,
        [action.keyName]: true
      }
    }
  }

  // SUM ALL SUB TOTAL FROM SPECIFIC ITEMS WITHOUT VALUE
  if (action.type === 'SUM_SUB_TOTAL_WITHOUT_VALUE') {
    const newState = JSON.parse(JSON.stringify(state.items))

    const newStateItems = Object.keys(newState)

    newStateItems.pop() // last key is super total
    const superTotal = Array(action.datas.length).fill(0)

    // foreach for each ITEM
    newStateItems.forEach((key, item) => {
      const items = newState[key].items
      const total = Array(action.datas.length).fill(0) // new total with zeros

      // foreach for each sub ITEM
      items.forEach(item => {
        // foreach for each data
        total.forEach((totalValue, keyTotal) => {
          if (item.datas === undefined) {
            item.datas = Array(action.datas.length)
              .fill()
              .map(u => ({
                value: 0,
                percent: 0
              }))
          }

          // check if datas is defined and array has elements
          if (item.datas !== undefined && item.datas.length > 0) {
            total[keyTotal] +=
              item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
            superTotal[keyTotal] +=
              item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
          }
        })
      })

      items.total = total
    })

    console.groupEnd()

    return {
      ...state
      // items: new_state,
      // total: superTotal
    }
  }

  if (action.type === 'UPDATE_DATE') {
    const totalDatas = action.value
    const modelItem = { value: 0, percent: 0 }
    const newStateItems = Object.entries(state.items)
    newStateItems.pop()
    const totalArray = new Array(totalDatas).fill(0)
    const totalItem = totalArray.map(item => modelItem)

    const newState = JSON.parse(JSON.stringify(state.items))

    newStateItems.forEach((key, item) => {
      const items = newState[key[0]].items
      const total = totalArray

      items.forEach(item => {
        item.total = totalArray
        total.forEach((totalValue, keyTotal) => {
          item.datas = totalItem
        })
      })

      newState[key[0]].total = totalArray
    })
    return {
      ...state,
      items: newState,
      total: totalArray
    }
  }

  if (action.type === 'EXIGIVEL_TOTAL') {
    const passivoCirculante = parseFloat(
      state.items.passivo_circulante.total[action.key_data]
    )
    const exigivelLongoPrazo = parseFloat(
      state.items.exigivel_longo_prazo.total[action.key_data]
    )
    const exigivelTotal = passivoCirculante + exigivelLongoPrazo

    return {
      ...state,
      items: {
        ...state.items,
        exigivel_total: {
          ...state.items.exigivel_total,
          total: state.items.exigivel_total.total.map((total, key) =>
            key === action.key_data ? exigivelTotal : total
          )
        }
      }
    }
  }

  if (action.type === 'TOTAL_BANCOS') {
    const parcelaCorrenteEmprestimos = parseFloat(
      state.items.total_bancos.items[10].datas[action.key_data].value
    )
    const importacao = parseFloat(
      state.items.total_bancos.items[9].datas[action.key_data].value
    )
    const aacAce = parseFloat(
      state.items.total_bancos.items[8].datas[action.key_data].value
    )
    const emprestimosCpMoedaEstrangeira = parseFloat(
      state.items.total_bancos.items[7].datas[action.key_data].value
    )
    const emprestimosCpMoedaNacional = parseFloat(
      state.items.total_bancos.items[6].datas[action.key_data].value
    )
    const duplicatasDescontadas = parseFloat(
      state.items.total_bancos.items[5].datas[action.key_data].value
    )

    const totalSum =
      parcelaCorrenteEmprestimos +
      importacao +
      aacAce +
      emprestimosCpMoedaEstrangeira +
      emprestimosCpMoedaNacional +
      duplicatasDescontadas

    return {
      ...state,
      items: {
        ...state.items,
        total_bancos: {
          ...state.items.total_bancos,
          total: state.items.total_bancos.total.map((total, key) =>
            key === action.key_data ? totalSum : total
          )
        }
      }
    }
  }

  if (action.type === 'PASSIVO_CIRCULANTE') {
    const outrosPassivosNaoOperacionais = parseFloat(
      state.items.passivo_circulante.items[2].datas[action.key_data].value
    )
    const outrosPassivosOperacionais = parseFloat(
      state.items.passivo_circulante.items[1].datas[action.key_data].value
    )
    const adiantamentoDeClientes = parseFloat(
      state.items.passivo_circulante.items[0].datas[action.key_data].value
    )
    const totalBancos = parseFloat(
      state.items.total_bancos.total[action.key_data]
    )
    const dividendosPartAdmEmpr = parseFloat(
      state.items.total_bancos.items[4].datas[action.key_data].value
    )
    const provisoesSociaisTributarias = parseFloat(
      state.items.total_bancos.items[3].datas[action.key_data].value
    )
    const provisoesIr = parseFloat(
      state.items.total_bancos.items[2].datas[action.key_data].value
    )
    const partesRelacionadas = parseFloat(
      state.items.total_bancos.items[1].datas[action.key_data].value
    )
    const fornecedores = parseFloat(
      state.items.total_bancos.items[0].datas[action.key_data].value
    )
    const outrasContas = parseFloat(
      state.items.passivo_circulante.items[3].datas[action.key_data].value
    )

    const totalPassivoCirculate =
      outrosPassivosNaoOperacionais +
      outrosPassivosOperacionais +
      adiantamentoDeClientes +
      totalBancos +
      dividendosPartAdmEmpr +
      provisoesSociaisTributarias +
      provisoesIr +
      partesRelacionadas +
      outrasContas +
      fornecedores
    return {
      ...state,
      items: {
        ...state.items,
        passivo_circulante: {
          ...state.items.passivo_circulante,
          total: state.items.passivo_circulante.total.map((total, key) =>
            key === action.key_data ? totalPassivoCirculate : total
          )
        }
      }
    }
  }

  if (action.type === 'REMOVE_DATE_PASSIVO') {
    const dataIndex = action.value.id

    return {
      ...state,
      items: {
        ...state.items,
        total_bancos: {
          ...state.items.total_bancos,
          items: state.items.total_bancos.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.total_bancos.total &&
            state.items.total_bancos.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        passivo_circulante: {
          ...state.items.passivo_circulante,
          items: state.items.passivo_circulante.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.passivo_circulante.total &&
            state.items.passivo_circulante.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        exigivel_longo_prazo: {
          ...state.items.exigivel_longo_prazo,
          items: state.items.exigivel_longo_prazo.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.exigivel_longo_prazo.total &&
            state.items.exigivel_longo_prazo.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        exigivel_total: {
          ...state.items.exigivel_total,
          items: state.items.exigivel_total.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.exigivel_total.total &&
            state.items.exigivel_total.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        resultado_exercicio_futuro: {
          ...state.items.resultado_exercicio_futuro,
          items: state.items.resultado_exercicio_futuro.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.resultado_exercicio_futuro.total &&
            state.items.resultado_exercicio_futuro.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        patrimonio_liquido: {
          ...state.items.patrimonio_liquido,
          items: state.items.patrimonio_liquido.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.patrimonio_liquido.total &&
            state.items.patrimonio_liquido.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        total:
          state.total &&
          state.total.filter((item, index) => index !== dataIndex)
      },
      total:
        state.total && state.total.filter((item, index) => index !== dataIndex)
    }
  }

  if (action.type === 'ADD_NEW_DATE_PASSIVO') {
    const dateBase = { value: 0, percent: 0 }
    const totalBase = 0

    return {
      ...state,
      items: {
        ...state.items,
        total_bancos: {
          ...state.total_bancos,
          items: state.items.total_bancos.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.total_bancos.total, totalBase]
        },
        passivo_circulante: {
          ...state.passivo_circulante,
          items: state.items.passivo_circulante.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.passivo_circulante.total, totalBase]
        },
        exigivel_longo_prazo: {
          ...state.exigivel_longo_prazo,
          items: state.items.exigivel_longo_prazo.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.exigivel_longo_prazo.total, totalBase]
        },
        exigivel_total: {
          ...state.exigivel_total,
          items: state.items.exigivel_total.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.exigivel_total.total, totalBase]
        },
        ativo_passivo_check: {
          ...state.ativo_passivo_check,
          items: state.items.ativo_passivo_check.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.ativo_passivo_check.total, totalBase]
        },
        resultado_exercicio_futuro: {
          ...state.resultado_exercicio_futuro,
          items: state.items.resultado_exercicio_futuro.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.resultado_exercicio_futuro.total, totalBase]
        },
        patrimonio_liquido: {
          ...state.patrimonio_liquido,
          items: state.items.patrimonio_liquido.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.patrimonio_liquido.total, totalBase]
        },
        total: state.total && [...state.total, totalBase]
      },
      total: state.total && [...state.total, totalBase]
    }
  }

  if (action.type === 'ATIVO_PASSIVO_CHECK') {
    const totalAtivo = action.total_ativo
    const totalPassivo = state.total[action.key_data]

    const totalFinal = totalAtivo - totalPassivo

    return {
      ...state,
      items: {
        ...state.items,
        ativo_passivo_check: {
          ...state.items.ativo_passivo_check,
          total: state.items.ativo_passivo_check.total.map((totalTemp, key) =>
            key === action.key_data ? totalFinal : totalTemp
          )
        }
      }
    }
  }

  return state
}

import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import Category from './category'

const DEFAULT_WIDTH = 250
const DEFAULT_HEIGHT = 60

export default function Column (props) {
  return (
    <div
      className='card'
      style={{
        backgroundColor: 'rgb(241, 242, 244)',
        width: DEFAULT_WIDTH,
        minWidth: DEFAULT_WIDTH
      }}
    >
      <div className='mt-3 mb-2 mx-3' style={{ fontSize: 14 }}>
        {props.column.title}
      </div>
      <Droppable droppableId={props.column.id} className='card-body'>
        {provided => (
          <div
            style={{ minHeight: DEFAULT_HEIGHT, height: '100%' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <InnerList categories={props.categories} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

// To avoid unnecessary re-renders, we can use React.memo() to wrap the InnerList component.
const InnerList = React.memo(({ categories }) => {
  return (
    <>
      {categories.map((category, index) => (
        <Category key={category.id} category={category} categoryIndex={index} />
      ))}
    </>
  )
})

InnerList.displayName = 'InnerList'

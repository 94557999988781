const INITIAL_STATE = {
  highlights: {},
  highlights_corporate: {},
  geracao_caixa_livre: {}
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'BOOTSTRAP_HIGHLIGHTS') {
    if (action.data) {
      return {
        highlights: action.data.highlights,
        highlights_corporate: action.data.highlights_corporate,
        geracao_caixa_livre: action.data.geracao_caixa_livre
      }
    }
  }
  return state
}

import axios from 'axios'

import { SPED_BASE_URL } from '../config/env'
import { SQS_PROVIDER_NAME } from '../config/consts'

import { SQSConsumer } from '../lib/sqs'

import { getAccessToken, getIdToken } from '../utils'

import { createQueue } from './sqs'

export const getClassificationMap = async ({ cnpj, id, topic, year }) => {
  cnpj = cnpj.replace(/\D/g, '')

  const token = await getAccessToken()
  const { data } = await axios.get(`${SPED_BASE_URL}/maps`, {
    params: {
      cnpj,
      id,
      topic,
      year
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

export const reclassify = async ({
  cnpj,
  spedId,
  topic,
  year,
  reclassifiedMaps,
  spreadItems,
  missing
}) => {
  cnpj = cnpj.replace(/\D/g, '')

  const accessToken = await getAccessToken()
  const idToken = await getIdToken()
  const queueUrl = await createQueue()
  const { data } = await axios.post(
    `${SPED_BASE_URL}/reclass-maps`,
    {
      cnpj,
      id: spedId,
      topic,
      queue_url: queueUrl,
      reclassified_maps: {
        [year]: {
          map: reclassifiedMaps,
          spread_items: spreadItems,
          missing,
          has_missing_values: !missing || missing.length === 0
        }
      }
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

  await getEndMessage({ queueUrl, idToken })

  return data
}

const getEndMessage = async ({ queueUrl, idToken }) => {
  const consumer = await SQSConsumer.getInstance({ accessToken: idToken })
  consumer.queueUrl = queueUrl
  consumer.addProvider(SQS_PROVIDER_NAME)

  return new Promise((resolve, reject) => {
    let errorMessage
    let successMessage
    let receivedEndMessage = false
    consumer.callback = async messages => {
      while (messages.length > 0) {
        const message = messages.shift()

        console.debug('message', message)

        if (message.content.action === 'error') {
          errorMessage = message
        } else if (message.content.action === 'message') {
          successMessage = message
        } else if (message.content.action === 'end') {
          receivedEndMessage = true
          break
        }
      }

      if (!receivedEndMessage) {
        return
      }

      consumer.stop()

      if (errorMessage) {
        reject(new Error('Ocorreu um erro ao reclassificar o sped'))
      }

      if (!successMessage) {
        reject(new Error('Nenhuma mensagem de sucesso foi recebida'))
      } else {
        resolve(successMessage.content.data)
      }
    }
    consumer.consume()
  })
}

export function updateSimpleData (key, value) {
  return {
    type: 'MUTACAO_UPDATE_SIMPLE_DATA',
    key,
    value
  }
}

export function updateTableExcel (el, keyNameItems, keyItem, keyData, value) {
  return {
    type: 'MUTACAO_UPDATE_TABLE_EXCEL',
    el,
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function sumTotalEl (el, keyNameItems, keyData, value) {
  return {
    type: 'MUTACAO_SUM_VALUES_FROM_ELEMENT',
    el,
    key_name_items: keyNameItems,
    key_data: keyData,
    value
  }
}

export function superSum (keyNameItems, keyItem, keyData, value) {
  return {
    type: 'MUTACAO_SUM_ALL_VALUES_BY_DATE',
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function porcentage (keyData) {
  return {
    type: 'MUTACAO_PORCENTAGE_ALL_ELEMENTS',
    key_data: keyData
  }
}

export function updateProgress (keyName) {
  return {
    type: 'MUTACAO_UPDATE_PROGRESS',
    keyName
  }
}

export function sumSubTotal (datas) {
  return {
    type: 'SUM_SUB_TOTAL_WITHOUT_VALUE',
    datas
  }
}

export function updateDate (value) {
  return {
    type: 'UPDATE_DATE',
    value
  }
}

export function bootstrap (data, totalDataEl) {
  return {
    type: 'BOOTSTRAP_MUTACAO',
    data,
    total_data_el: totalDataEl
  }
}

export function removeDataMutacao (value, totalDates) {
  return {
    type: 'REMOVE_DATE_MUTACAO',
    value,
    total_dates: totalDates
  }
}

export function addDataMutacao () {
  return {
    type: 'ADD_NEW_DATE_MUTACAO'
  }
}

export function diferencaPatrimonioLiquido (totalPassivo, keyData) {
  return {
    type: 'DIFERENCA_PATRIMONIO_LIQUIDO',
    total_passivo: totalPassivo,
    key_data: keyData
  }
}

export function patrimonioLiquidoInicial (totalDatas, keyData) {
  return {
    type: 'PATRIMONIO_LIQUIDO_INICIAL',
    total_datas: totalDatas,
    key_data: keyData
  }
}

export function lucroLiquidoExercicio (dre, keyData) {
  return {
    type: 'LUCRO_LIQUIDO_EXERCICIO',
    dre,
    key_data: keyData
  }
}

export function patrimonioInicialEnabledEdit () {
  return {
    type: 'PATRIMONIO_INICIAL_ENABLEDEDIT'
  }
}

import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { MdTrendingDown } from 'react-icons/md'

import * as SidebarActions from '../../store/actions/sidebar'

import { getClassificationMap, reclassify } from '../../services/sped'

import { Dnd } from './components'
import Sidebar from '../../components/sidebar'
import PageTitle from '../../components/pageTitle'
import { getInitialData } from './components/Dnd/initial-data'

export default function Reclassificacao () {
  const history = useHistory()
  const [state, setState] = useState({
    status: 'pending',
    loading: true,
    columns: {},
    columnOrder: [],
    categories: [],
    originalData: null
  })
  const allState = useSelector(state => ({
    dados: state.dados,
    ativo: state.ativo,
    passivo: state.passivo,
    dre: state.dre,
    mutacao: state.mutacao,
    fundos: state.fundos,
    parceiros: state.parceiros,
    api: state.api,
    rating: state.rating
  }))
  const dispatch = useDispatch()
  const { classe, ano, cnpj } = useParams()

  useEffect(() => {
    dispatch(SidebarActions.clickSidebar(undefined))
  }, [])

  useLayoutEffect(() => {
    const datas = allState.dados.datas
    if (!datas || datas.length === 0 || state.status !== 'pending') {
      return
    }

    const run = async () => {
      try {
        const spedId = datas.find(item => {
          const year = item.data.toLocaleDateString('pt-BR', {
            year: 'numeric'
          })

          return year === ano
        })?.sped_import_id

        if (!spedId) {
          throw new Error('Não foi possível encontrar o ECD')
        }

        const data = await getClassificationMap({
          cnpj,
          id: spedId,
          topic: classe,
          year: ano
        })

        setState(prev => ({
          ...prev,
          spedId,
          loading: false,
          status: 'loaded',
          ...getInitialData(data),
          originalData: data
        }))
      } catch (err) {
        const message =
          err.response?.data?.message ||
          err.message ||
          'Ocorreu um erro ao carregar a reclassificação!'
        console.error(err)
        await Swal.fire({
          title: 'Erro!',
          text: message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })

        history.push(`/dados/${cnpj}`)
      }
    }
    setState(prev => ({ ...prev, status: 'fetching' }))
    run()
  }, [classe, ano, cnpj, allState, state.status])

  const handleSave = async () => {
    try {
      const reclassifiedMaps = Object.fromEntries(
        Object.entries(state.columns).map(([key, values]) => {
          return [key, values.categoryIds]
        })
      )
      const missing = reclassifiedMaps['Não classificado']
      delete reclassifiedMaps['Não classificado']

      Swal.fire({
        title: 'Salvando!',
        text: 'Aguarde enquanto aplicamos a reclassificação...',
        icon: 'info',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        }
      })
      await reclassify({
        cnpj,
        spedId: state.spedId,
        topic: classe,
        year: ano,
        reclassifiedMaps,
        spreadItems: state.originalData.spread_items,
        missing
      })

      Swal.hideLoading()
      Swal.close()

      await Swal.fire({
        title: 'Sucesso!',
        text: 'Reclassificação salva com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      window.location.reload()
    } catch (err) {
      const message =
        err.response?.data?.message ||
        err.message ||
        'Ocorreu um erro ao reclassificar!'
      console.error(err)
      await Swal.fire({
        title: 'Erro!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  return (
    <div
      className='container-fluid min-vh-100'
      style={{ padding: 0, margin: 0 }}
    >
      <div className='row min-vh-100 no-gutters'>
        <Sidebar />
        <div className='content col-auto d-flex'>
          <PageTitle
            title={`Reclassificação dos ${parseClasse(classe)}`}
            subtitle=''
            icon={MdTrendingDown}
            btnSalvar={handleSave}
          />
          <div
            className='bodyContent'
            style={{ paddingTop: '1em', overflow: 'scroll', flex: 1 }}
          >
            {state.loading ? (
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div>
                  <div
                    className='spinner-border'
                    role='status'
                    style={{ width: '3rem', height: '3rem' }}
                  />
                </div>
              </div>
            ) : (
              <Dnd state={state} onChange={setState} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const parseClasse = classe => {
  switch (classe) {
    case 'ativo':
      return 'ativos'
    case 'passivo':
      return 'passivos'
    case 'dre':
      return 'DREs'
    default:
      throw new Error('Classe inválida')
  }
}

import React from 'react'
import { connect } from 'react-redux'
import { FiMaximize2 } from 'react-icons/fi'

import * as SidebarActions from './../../store/actions/sidebar'

import './subtotal.css'

const SubTotalToogle = props => {
  const { sidebar, dispatch } = props

  const subtotalChange = () => {
    dispatch(SidebarActions.showSubtotal(!sidebar.showSubtotal))
  }

  return (
    <div className='subtotal-root'>
      <div
        className={
          sidebar.showSubtotal
            ? 'subtotal-normal subtotal-btn'
            : 'subtotal-hide2 subtotal-btn'
        }
        onClick={() => subtotalChange()}
      >
        <FiMaximize2 size={20} className='chart-icon' />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  sidebar: state.sidebar
})

export default connect(mapStateToProps)(SubTotalToogle)

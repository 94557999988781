import React from 'react'
import { connect } from 'react-redux'

import './../item-currency/item-currency.css'

class TotalAtivo extends React.Component {
  constructor (props) {
    super(props)

    this.props.ativo.total =
      this.props.ativo.total === undefined ||
      this.props.ativo.total.length === 0
        ? new Array(this.props.dados.datas.length).fill(0)
        : this.props.ativo.total
  }

  render () {
    const { dados, ativo } = this.props

    const getDate = date => {
      const d = new Date(date)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    return (
      <div className='ativoTotal'>
        <div className='row justify-content-center no-gutters'>
          <div className='col-10'>
            <div className='row justify-content-start'>
              <div className='total-txt col-auto text-right'>Total</div>
              {dados.datas.map((date, keyData) => (
                <div className='col-auto total-bloc' key={keyData}>
                  <div className='total-date'>{getDate(date.data)}</div>
                  <div className='total-secondLine'>
                    <span>R$</span>
                    <span>
                      {ativo.items &&
                        ativo.items.total &&
                        ativo.items.total.length > 0 &&
                        ativo.items.total[keyData].toLocaleString('pt-BR')}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo
})

export default connect(mapStateToProps)(TotalAtivo)

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './sidebar.css'

const ItemMenuSidebar = props => {
  const { name, icon, url, margin, active, dados, onClick } = props

  const Component = onClick ? 'div' : Link

  return (
    <li
      style={
        margin
          ? { margin: '0px', width: '100%' }
          : { margin: '5px 0px 5px 8px' }
      }
      className={'itemMenu ' + (active ? 'activeMenu' : '')}
      {...(onClick ? { onClick } : null)}
    >
      <Component
        to={url + '/' + dados.cnpj}
        style={{ color: '#ffffff4d', textAlign: 'center', cursor: 'pointer' }}
      >
        <div style={{ fontSize: '25px' }}>{icon()}</div>
        <div
          style={{
            fontFamily: 'Open Sans',
            fontWeight: '700',
            fontSize: '12px',
            marginTop: '2px',
            position: 'relative',
            top: '-5px'
          }}
        >
          {name}
        </div>
      </Component>
    </li>
  )
}

const maps2Props = state => ({
  dados: state.dados
})

export default connect(maps2Props)(ItemMenuSidebar)

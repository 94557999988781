import React from 'react'
import { connect } from 'react-redux'

import { FaPercentage } from 'react-icons/fa'

import { checkIfMinus } from './../utils'

import './../highlights.css'

const TableIndicadores = props => {
  const { dados, highlights } = props
  const { indicadores } = highlights.highlights

  const IndicadoresContent = [
    {
      name: 'Patrimônio Líquido Tangível',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.patrimonio_liquido_tangivel[index].percent,
      value: index =>
        indicadores &&
        indicadores.patrimonio_liquido_tangivel[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Capital Circulante Líquido',
      type: 'normal',
      porcent: index =>
        indicadores && indicadores.capital_circulante_liquido[index].percent,
      value: index =>
        indicadores &&
        indicadores.capital_circulante_liquido[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Capital de Giro Próprio',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.capital_giro_proprio[index].percent,
      value: index =>
        indicadores &&
        indicadores.capital_giro_proprio[index].value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'EBITDA',
      type: 'normal',
      porcent: index => indicadores && indicadores.ebitda[index].percent,
      value: index =>
        indicadores &&
        indicadores.ebitda[index].value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'IOG - Invest. Oper. Giro',
      type: 'highlight',
      porcent: index => indicadores && indicadores.iog[index].percent,
      value: index =>
        indicadores &&
        indicadores.iog[index].value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    },

    {
      name: 'Necessidade Adicional de Giro',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.necessidade_adicional_giro[index].percent,
      value: index =>
        indicadores &&
        indicadores.necessidade_adicional_giro[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Inversões em Ativo Fixo',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.inversoes_em_ativo_fixo[index].percent,
      value: index =>
        indicadores &&
        indicadores.inversoes_em_ativo_fixo[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Endividamento Bancário CP',
      type: 'normal',
      porcent: index =>
        indicadores && indicadores.endividamento_bancario_cp[index].percent,
      value: index =>
        indicadores &&
        indicadores.endividamento_bancario_cp[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Endividamento Bancário Total',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.endividamento_bancario_total[index].percent,
      value: index =>
        indicadores &&
        indicadores.endividamento_bancario_total[index].value.toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        )
    },

    {
      name: 'Dívida Líquida',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.divida_liquida[index].percent,
      value: index =>
        indicadores &&
        '' +
          indicadores.divida_liquida[index].value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })
    },

    {
      name: 'Dívida Líquida em Dias de Fat. Líq.',
      type: 'highlight',
      porcent: index =>
        indicadores &&
        indicadores.divida_liquida_em_dias_fat_liq[index].percent,
      value: index =>
        indicadores &&
        '' + indicadores.divida_liquida_em_dias_fat_liq[index].value
    },

    {
      name: 'Dívida Líquida / EBITDA',
      type: 'normal',
      porcent: index =>
        indicadores && indicadores.divida_liquida_sobre_ebitda[index].percent,
      value: index =>
        indicadores && '' + indicadores.divida_liquida_sobre_ebitda[index].value
    },

    {
      name: 'Dívida Líquida CP / EBITDA',
      type: 'normal',
      porcent: index =>
        indicadores &&
        indicadores.divida_liquida_cp_sobre_ebitda[index].percent,
      value: index =>
        indicadores &&
        '' + indicadores.divida_liquida_cp_sobre_ebitda[index].value
    },

    {
      name: 'Desp. Fin. / EBITDA',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.desp_fin_sobre_ebitda[index].percent,
      value: index =>
        indicadores && '' + indicadores.desp_fin_sobre_ebitda[index].value
    },

    {
      name: 'Leverage',
      type: 'normal',
      porcent: index => indicadores && indicadores.leverage[index].percent,
      value: index => indicadores && '' + indicadores.leverage[index].value
    },

    {
      name: 'End. Oneroso Líquido',
      type: 'highlight',
      porcent: index =>
        indicadores && indicadores.endividamento_oneroso_liquido[index].percent,
      value: index =>
        indicadores &&
        '' + indicadores.endividamento_oneroso_liquido[index].value
    },

    {
      name: 'Liquidez Corrente',
      type: 'normal',
      porcent: index =>
        indicadores && indicadores.liquidez_corrente[index].percent,
      value: index =>
        indicadores && '' + indicadores.liquidez_corrente[index].value
    },

    {
      name: 'ROIC - Retorno Sobre o Capital Investido',
      type: 'highlight',
      porcent: index => indicadores && indicadores.roic[index].percent,
      value: index => indicadores && '' + indicadores.roic[index].value
    }
  ]

  const getDate = date => {
    const d = new Date(date)
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }

  return (
    <table
      className='table table-sm table-striped col-6 table-root'
      id='tableIndicadoresPDF'
    >
      <thead className='thead-dark bg-primary'>
        <tr>
          <th scope='col'>Indicadores</th>
          {
            // ADD COLUMN DATE
            dados.datas.map((date, index) => {
              return (
                <th key={index} scope='col' className='col-data'>
                  <div className='col-date-item'>{getDate(date.data)}</div>
                  <div className='col-porcent-item'>
                    <FaPercentage />
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {IndicadoresContent.map((item, index) => {
          return (
            <tr key={index} className={item.type + ' row-item'}>
              <th scope='row' className='table-name-item'>
                {item.name}
              </th>
              {
                // ADD COLUMN DATE
                dados.datas.map((date, index) => {
                  return (
                    <th key={index} scope='col' className='col-data'>
                      <div
                        className={
                          (checkIfMinus(item.value(index))
                            ? 'minus-value'
                            : 'plus-value') + ' col-date-item'
                        }
                      >
                        {item.value(index)}
                      </div>
                      <div className='col-porcent-item'>
                        <span>{item.porcent(index)}</span>
                        <span>
                          <FaPercentage />
                        </span>
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const mapStateToProps = state => ({
  dados: state.dados,
  dre: state.dre,
  highlights: state.highlights
})

export default connect(mapStateToProps)(TableIndicadores)

import {
  AWS_COGNITO_REGION,
  AWS_USER_POOLS_ID,
  AWS_USER_POOLS_WEB_CLIENT_ID
} from './env'

export const amplifyConfig = {
  awsCognitoRegion: AWS_COGNITO_REGION,
  awsUserPoolsId: AWS_USER_POOLS_ID,
  awsUserPoolsWebClientId: AWS_USER_POOLS_WEB_CLIENT_ID
}
